import DashboardAPI from "../apis/DashboardAPI";
import { iAdminTag, iFormTag, iTagDTO } from "../utilities/APIInterfaces";
import { GeneratePatchCmds } from "../utilities/HelperFunctions";

class TagService {
    async GetOrgTags(opeId: string, loader: (v: boolean) => void): Promise<iTagDTO[]> {
        let tagData = null;
        loader(true);
        tagData = await DashboardAPI.callForData<iTagDTO[]>({
            method: "GET",
            route: `tag/org/${opeId}`});
        loader(false);

        return tagData;
    }

    async GetFormTags(formUuid: string, loader: (v: boolean) => void): Promise<iFormTag[]> {
        let tagData = null;
        loader(true);
        tagData = await DashboardAPI.callForData<iFormTag[]>({
          method: "GET",
          route: `tag/form/${formUuid}`});
        loader(false); 

        return tagData;
    }

    async GetAdminFormTags(formUuid: string, loader: (v: boolean) => void): Promise<iAdminTag[]> {
        let tagData = null;
        loader(true);
        tagData = await DashboardAPI.callForData<iAdminTag[]>({
          method: "GET",
          route: `tag/form/${formUuid}/admin`});
        loader(false); 

        return tagData;
    }
    
    async AddTags(formUuid: string | null, tags: iTagDTO[], loader: (v: boolean) => void) {
        let tagData = null;
        loader(true);
        tagData = await DashboardAPI.call({
            method: "POST",
            route: `tag/form/${formUuid}`, 
            body: tags
        });
        loader(false);

        return tagData;
    }

    async UpdateTag(oldData: iTagDTO, newData: iTagDTO,  loader: (v: boolean) => void) {
        let commands = GeneratePatchCmds(oldData, newData);
        loader(true);
        await DashboardAPI.call({
            method: "PATCH",
            route: `tag/${oldData.id}`, 
            body: commands
        });
        loader(false);
    }

    async SetSubmissionsTags(formUuid: string | null, submissionsUuids: string[], formTagIds: number[], allowDelete: boolean, loader: (v: boolean) => void){
        loader(true);
        let response =  DashboardAPI.call({
          route: `tag/form/${formUuid}/submission`,
          method: 'POST',
          body: {
            submissionUuids: submissionsUuids,
            FormTagIds: formTagIds,
            allowDelete: allowDelete
          }
        });
        loader(false);
        return response
      }

    async RemoveFormTag(id: number, formUuid: string, loader: (v: boolean) => void) {
        loader(true);
        await DashboardAPI.call({
            method: "DELETE",
            route: `tag/${id}/form/${formUuid}`
        });
        loader(false);
    }

    async DeleteTag(id: number, loader: (v: boolean) => void) {
        loader(true);
        await DashboardAPI.call({
            method: "DELETE",
            route: `tag/${id}`
        });
        loader(false);
    }

    MapToDto(tag: iAdminTag | undefined, orgId: number): iTagDTO {
        let tagDTO: iTagDTO = {
            id: tag!.id,
            tag_Name: tag!.tag_Name,
            org_Id: orgId
        };
        return tagDTO;
    }
}

const tagService = new TagService()
export default tagService;