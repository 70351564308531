import { Container, Box, Button, Typography, Menu, MenuItem, ListItemText, Alert } from '@mui/material';
import { TableContainer, Table, TableHead, TableBody, TableRow, TableCell, TablePagination } from '@mui/material';
import TableRowsLoader, { type TableLoaderProps } from './TableRowsLoader';
import { useSnackbar } from 'notistack';
import { UserContext } from '../contexts/UserContext';
import { useState, useContext, useEffect, useMemo } from 'react';
import { inceptiaGreenAlphaColors } from '../utilities/CSS';
import MoreHorizIcon from '@mui/icons-material/MoreHoriz';
import ConfirmationDialog from './ConfirmationDialog';
import { AdminUsersDataContext } from '../contexts/AdminUsersDataContext';
import { OrganizationContext } from '../contexts/OrganizationContext';
import { useNavigate } from 'react-router-dom';
import { visuallyHidden } from '@mui/utils';
import AdminUsersService from '../services/AdminUsersService';

const TableWrapper = ({ children }: { children: React.ReactNode }) => {
  const thSX = {
    color: '#1e1e1e',
    fontWeight: 'bold',
    textTransform: 'uppercase',
    whiteSpace: 'nowrap'
  };

  return (
    <TableContainer>
      <Table aria-label='admin users table' size='small'>
        <TableHead>
          <TableRow>
            <TableCell component="th" sx={thSX}>Name</TableCell>
            <TableCell component="th" sx={thSX}>Email</TableCell>
            <TableCell component="th" sx={thSX}>Role</TableCell>
            <TableCell component="th" sx={thSX}>Status</TableCell>
            <TableCell component="th" sx={thSX}>Access</TableCell>
            <TableCell component="th" sx={thSX}>
              {/* Fix for empty header cell accessibility error */}
              <Box sx={visuallyHidden}>Actions</Box>
            </TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {children}
        </TableBody>
      </Table>
    </TableContainer>
  );
}

const TableLoader = ({ colsNum, rowsNum, animation = 'pulse' }: TableLoaderProps) => {
  return (
    <TableWrapper>
      <TableRowsLoader colsNum={colsNum} rowsNum={rowsNum} animation={animation} rowHeight={55} />
    </TableWrapper>
  );
};

export default function AdminUsersTable({ searchQuery }: { searchQuery: string }) {
  const { enqueueSnackbar } = useSnackbar();
  const userContext = useContext(UserContext);
  const currentUserAuthId = userContext.user?.authId?.toString();

  const { usersData, isAdminUsersDataLoaded, setIsAdminUsersDataLoaded } = useContext(AdminUsersDataContext);
  const { userData, setUserData } = useContext(AdminUsersDataContext);

  const [isDeleteDialogOpen, setIsDeleteDialogOpen] = useState(false);
  const [isDeleting, setIsDeleting] = useState(false);
  const [deleteUserData, setDeleteUserData] = useState({} as { userUuid: string; userName: string; });

  const navigate = useNavigate();

  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(5);

  const [isCannotDeleteDialogOpen, setIsCannotDeleteDialogOpen] = useState(false);

  const [anchorEl, setAnchorEl] = useState<HTMLButtonElement | null>(null);
  const isMoreMenuOpen = Boolean(anchorEl);

  const filteredUsers = useMemo(() => {
    // Fields to include in search
    const searchFields = ['name', 'email', 'role', 'status', 'productId'];

    const usersDataArray = Array.isArray(usersData) ? usersData : [];

    if (!usersDataArray) return [];
    return usersDataArray.filter(user => {
      return searchFields.some(key =>
        user[key]?.toString().toLowerCase().includes(searchQuery.toLowerCase())
      );
    });
  }, [usersData, searchQuery]);

  useEffect(() => {
    const DeleteAdminUserAsync = async () => {
      if (currentUserAuthId) {
        await AdminUsersService.DeleteAdminUser(deleteUserData.userUuid, currentUserAuthId, (v) => v)
          .then(result => {
            const err = result as any;
            if (err.hasOwnProperty('status') && err.status === 405) setIsCannotDeleteDialogOpen(true);
          })
          .catch(error => {
            enqueueSnackbar(error.toString());
            console.log("DeleteAdminUserAsync 1", error);
          }).finally(() => {
            setIsAdminUsersDataLoaded(false);
            setIsDeleting(false);
            setIsDeleteDialogOpen(false);
          });
      }
    };

    if (isDeleting) DeleteAdminUserAsync();
  }, [isDeleting, deleteUserData, enqueueSnackbar, currentUserAuthId, setIsAdminUsersDataLoaded]);


  // Avoid a layout jump when reaching the last page with empty rows.
  const emptyRows = page > 0 ? Math.max(0, (1 + page) * rowsPerPage - filteredUsers!.length) : 0;

  // EVENT HANDLERS ===========================================================
  const handleMoreButtonClick = (e: React.MouseEvent<HTMLButtonElement> | React.KeyboardEvent<HTMLButtonElement>) => {
    // Don't want to trigger the table row click event handler when clicking on the button
    e.stopPropagation();
    setAnchorEl(e.currentTarget);
  };

  const handleMoreMenuItemClick = (e: React.MouseEvent<HTMLLIElement>) => {
    // Don't want to trigger the table row click event handler when clicking on the button
    e.stopPropagation();
  
    const userAuthId = anchorEl!.getAttribute('data-row-id') || '';
    const user = usersData?.find(user => user.authId === userAuthId);
    
    if (user) {
      setUserData(user);
    }
  
    // Need to know which menu item was clicked (edit or delete)
    const action = (e.target as HTMLElement).closest('li')?.getAttribute('data-menu-action')?.toLowerCase();
    switch (action) {
      case 'edit':
        navigate('/admin/useredit/' + user?.authId, { state: { user } });
        break; 
      case 'delete':
        const userName = user?.name || 'Did Not Find user Name';
        const userUuid = user?.authId || '';
  
        //setDeleteUserData({ userUuid, userName });
        setIsDeleteDialogOpen(true);
        break;
    }
  
    setAnchorEl(null);
  };

  const handleMoreMenuClose = (e: React.MouseEvent<HTMLButtonElement | HTMLLIElement>) => {
    // Don't want to trigger the table row click event handler when closing the more menu
    e.stopPropagation();
    setAnchorEl(null);
  };

  const handleUserClick = (event: React.MouseEvent<HTMLTableCellElement>, authId: string) => {
    event.preventDefault();
    navigate('/admin/useredit/' + authId);
};

  const handlePageChange = (e: React.MouseEvent<HTMLButtonElement, MouseEvent> | null, newPage: number) => {
    setPage(newPage);
  };

  const handleRowsPerPageChange = (e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    setRowsPerPage(parseInt(e.target.value, 10));
    setPage(0);
  };

  const tdSX = {
    py: 1.125
  };

  

  return (
    <Container sx={{ py: 3 }}>
      {/* Loading... */}
      {!isAdminUsersDataLoaded && <TableLoader colsNum={6} rowsNum={filteredUsers.length || 10} />}

      {/* Loaded, but no users returned from the API */}
      {isAdminUsersDataLoaded && filteredUsers.length === 0 && (
        <TableWrapper>
          <TableRow>
            <TableCell colSpan={8} sx={{ borderBottom: 'none' }}>
              <Typography
                variant='body1'
                sx={{
                  // WAVE accessibility contrast error if color matches Figma color of #b8b8b8, so darken it enough to pass (grey.600 = #757575)
                  color: 'grey.600',
                  fontWeight: 500,
                  py: 3.75,
                  textAlign: 'center'
                }}
              >No users found...</Typography>
            </TableCell>
          </TableRow>
        </TableWrapper>
      )}

      {/* Loaded, with users returned from the API */}
      {isAdminUsersDataLoaded && filteredUsers.length > 0 && (
        <TableWrapper>
          {(rowsPerPage > 0 ? filteredUsers!.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage) : filteredUsers!).map(row => (
            <TableRow
              hover
              key={row.userId as string | number}
              role='button' // Keyboard accessibility
              tabIndex={0} // Keyboard accessibility
              sx={{
                cursor: 'pointer',
                '&:last-child td, &:last-child th': { border: 0 },
                '&:focus-visible': { backgroundColor: inceptiaGreenAlphaColors.hover }
              }}
            >
              <TableCell
                onClick={(event) => handleUserClick(event, row.authId)} sx={{ cursor: 'pointer', color: 'InceptiaGreen.main', fontWeight: '500' }}
              >
                {row.firstname} {row.lastName}
              </TableCell>
              <TableCell sx={tdSX}>{row.email}</TableCell>
              <TableCell sx={tdSX}>{row.role}</TableCell>
              <TableCell sx={tdSX}>Active</TableCell>
              <TableCell sx={tdSX}>{row.productId.join(', ')}</TableCell>
              <TableCell sx={{ ...tdSX, pr: 0 }}>
                <Button
                  id={`more-button-${row.authId}`}
                  aria-controls={isMoreMenuOpen ? 'more-menu' : undefined}
                  aria-expanded={isMoreMenuOpen ? 'true' : undefined}
                  aria-haspopup='true'
                  aria-label='More user options'
                  color='inherit'
                  data-row-id={row.authId}
                  onClick={handleMoreButtonClick}
                  onKeyDown={(e: React.KeyboardEvent<HTMLButtonElement>) => {
                    // Keyboard accessibility - open user when pressing enter or space
                    if (e.key === 'Enter' || e.key === ' ') handleMoreButtonClick(e);
                  }}
                  sx={{ minWidth: 'revert' }}
                >
                  <MoreHorizIcon />
                </Button>
              </TableCell>
            </TableRow>
          ))}
          {emptyRows > 0 && (
            <TableRow sx={{ height: 53 * emptyRows }}>
              <TableCell colSpan={8} />
            </TableRow>
          )}
        </TableWrapper>
      )}

      <TablePagination
        component='div'
        count={filteredUsers.length}
        rowsPerPage={rowsPerPage}
        page={page}
        rowsPerPageOptions={[10, 25, 50, { value: -1, label: 'All' }]}
        onPageChange={handlePageChange}
        onRowsPerPageChange={handleRowsPerPageChange}
        sx={{
          '&, & .MuiTablePagination-input .MuiSvgIcon-root': { color: '#000' }
        }}
        slotProps={{
          actions: {
            previousButton: { 'aria-label': 'previous page' },
            nextButton: { 'aria-label': 'next page' }
          },
          // MUI generates WAVE "Missing user label" error, the options below fix it
          // https://stackoverflow.com/questions/66642553/tablepagination-mui-missing-user-label-accessibility-issue-in-wave-tool
          select: {
            id: 'rows-per-page-select',
            inputProps: {
              'aria-label': 'rows per page',
              'aria-labelledby': 'rows-per-page-select'
            }
          }
        }}
      />

      {/* Don't want the menu to be duplicated for each row in the table */}
      <Menu
        id='more-menu'
        anchorEl={anchorEl}
        open={isMoreMenuOpen}
        onClose={handleMoreMenuClose}
        MenuListProps={{
          'aria-labelledby': 'more-button',
          dense: true,
          sx: {
            minWidth: '180px',
            textAlign: 'right'
          }
        }}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'right'
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'right'
        }}
      >
        <MenuItem data-menu-action='edit' onClick={handleMoreMenuItemClick}>
          <ListItemText primaryTypographyProps={{ fontWeight: '500' }}>Edit user</ListItemText>
        </MenuItem>
        <MenuItem
          data-menu-action='delete'
          onClick={handleMoreMenuItemClick}
        >
          <ListItemText primaryTypographyProps={{ fontWeight: '500' }}>Delete user</ListItemText>
        </MenuItem>
      </Menu>

      {/* User CAN be deleted */}
      {isDeleteDialogOpen && <ConfirmationDialog
        ariaPrefix='delete-user'
        dialogTitle='Are you sure?'
        singleButton={false}
        ctaButtonText='Delete'
        ctaButtonWorkingText='Deleting...'
        cancelButtonText='Cancel'
        open={isDeleteDialogOpen}
        setOpenDialog={setIsDeleteDialogOpen}
        isWorking={isDeleting}
        setIsWorking={setIsDeleting}
      >
        <Typography variant='body2' sx={{ mb: 2 }}>This will delete <b>{deleteUserData.userName}</b> from the system.</Typography>
        <Alert severity='warning'>It will be permanently deleted and cannot be recovered.</Alert>
      </ConfirmationDialog>}

      {/* User CANNOT be deleted */}
      {isCannotDeleteDialogOpen && <ConfirmationDialog
        ariaPrefix='cannot-delete-user'
        dialogTitle='Unable to Delete user'
        singleButton={true}
        ctaButtonText='Close'
        ctaButtonWorkingText='Closing...'
        cancelButtonText=''
        open={isCannotDeleteDialogOpen}
        setOpenDialog={setIsCannotDeleteDialogOpen}
      >
        <Typography variant='body2' sx={{ mb: 2 }}>The <b>{deleteUserData.userName}</b> user cannot be deleted at this time.</Typography>
      </ConfirmationDialog>}
    </Container>
  );
}