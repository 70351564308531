import { useState, useCallback, useContext } from 'react';
import CloseIcon from '@mui/icons-material/Close';
import AccessTimeIcon from '@mui/icons-material/AccessTime';
import { inceptiaGreenAlphaColors } from '../utilities/CSS';
import { useDropzone } from 'react-dropzone';
import { Dialog, DialogTitle, DialogActions, Button, IconButton, useMediaQuery, useTheme, DialogContent, MenuItem, Grid, Link, FormControl, OutlinedInput, InputLabel, Typography, Select, SelectChangeEvent, Box, Alert } from "@mui/material";
import AdminUsersService from '../services/AdminUsersService';
import { OrganizationContext } from '../contexts/OrganizationContext';
import { UserContext } from '../contexts/UserContext';


type ProductSelectProps = {
    // Define the props for the ProductSelect component here
    selectedProduct: string;
    onProductChange: (product: string) => void;
};

const ProductSelect = ({ selectedProduct, onProductChange }: ProductSelectProps) => {
    const productTypes = ['Grace', 'Student Outreach', 'Repayment Counseling'];

    const handleChange = (event: SelectChangeEvent<string>) => {
        onProductChange(event.target.value); // Call onProductChange with the selected product
    };

    return (
        <>

            <Box sx={{ pt: 2 }}>
                <FormControl fullWidth>
                    <InputLabel id="product-label" style={{ lineHeight: 'normal' }}>Product</InputLabel>
                    <Select
                        labelId="product-label"
                        id="product-select"
                        label="Product"
                        value={selectedProduct}
                        onChange={handleChange}
                    >
                        {productTypes.map((productType, index) => (
                            <MenuItem key={index} value={productType}>{productType}</MenuItem>
                        ))}
                    </Select>
                    {selectedProduct && (
                        <Typography sx={{ pt: 1 }}>
                            {/* FUTURE: show last upload with amount of students and the date*/}
                            <AccessTimeIcon fontSize='small' />  Last Uploaded {new Date().toLocaleDateString('en-US', { year: 'numeric', month: 'short', day: 'numeric' })}
                        </Typography>
                    )}
                </FormControl>
            </Box>

        </>
    )
}

interface UploadFileTemplateProps {
    open: boolean;
    onClose: () => void;
};
const UploadFileTemplate = ({ open, onClose }: UploadFileTemplateProps) => {
    const [file, setFile] = useState<File | null>(null);

    const onDrop = useCallback((acceptedFiles: File[]) => {
        if (acceptedFiles.length > 0) {
            setFile(acceptedFiles[0]);
        }
    }, []) // Add an empty array as the second argument to useCallback

    const { getRootProps, getInputProps, isDragActive, open: openFileDialog } = useDropzone({ onDrop, multiple: false });

    const handleUpload = () => {
        if (file) {
            // handle the file upload here
            console.log('Uploading file:', file);
        }
        onClose();
    };

    return (
        <>
            <FormControl variant="outlined" fullWidth>
                <Typography sx={{ fontWeight: 700, pt: 2, pb: 1 }}>Upload Template</Typography>
                <Typography variant="body1">Enter and save user information into the template and upload here.</Typography>
                <DialogContent>
                    <Box
                        sx={{
                            display: 'flex',
                            alignItems: 'center',
                            justifyContent: 'center',
                            height: '100px',
                            borderRadius: '8px',
                            backgroundColor: 'grey.100',
                            width: '100%',
                            boxSizing: 'border-box', // Add this line
                        }}
                    >
                        <input {...getInputProps()} />
                        <Typography>
                            Drag & Drop or{' '}
                            <Link
                                component="button"
                                variant="body1"
                                color="primary"
                                underline="always"
                                onClick={openFileDialog}
                                sx={{ cursor: 'pointer' }}
                            >
                                Browse
                            </Link>
                        </Typography>
                    </Box>
                </DialogContent>
            </FormControl>
        </>
    )

};


type AdminUploadStudentModalProps = {
    uploadStudentIsOpen: boolean;
    setUploadStudentIsOpen: (uploadStudentIsOpen: boolean) => void;
};

const AdminUploadStudentModal = ({ uploadStudentIsOpen, setUploadStudentIsOpen }: AdminUploadStudentModalProps) => {
    const [selectedProduct, setSelectedProduct] = useState('Grace'); // Set the default value here
    const theme = useTheme();
    const fullScreen = useMediaQuery(theme.breakpoints.down('sm'));
    const [messageAlert, setMessageAlert] = useState<{ open: boolean, message: string, severity: string }>({ open: false, message: '', severity: '' });
    const [files, setFiles] = useState<File[]>([]);
    const { organization } = useContext(OrganizationContext);
    const userContext = useContext(UserContext);
    const currentUserId = userContext.user?.id;

    const { getRootProps, getInputProps, open: openFileDialog } = useDropzone({
        accept: selectedProduct === 'Grace' ? {
            "application/vnd.ms-excel": [".xls"],
            "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet": [".xlsx"],
            "text/csv": [".csv"],
            "text/plain": [".txt"]
        } : {
            "text/plain": [".txt"]
        },
        noClick: true,
        noKeyboard: true,
        onDrop: acceptedFiles => {
            setFiles(acceptedFiles);
        }
    });

    const handleFileUpload = async () => {
        if (files && selectedProduct) {
            const file = files[0];
            const formData = new FormData();
            formData.append('file', file);
            formData.append('product', selectedProduct)
            formData.append('orgID', organization?.id.toString() || '0');
            formData.append('userId', currentUserId?.toString() || '0')

            try {
                const response = await AdminUsersService.UploadFile(formData);

                if (!response.ok) {
                    throw new Error(`Failed to upload file: ${response.statusText}`);
                }

                console.log('File uploaded successfully');
            } catch (error) {
                console.error('Error uploading file:', error);
            }
        } else {
            console.error('File or selected product is missing');
        }
    };

    const handleClose = (event: React.MouseEvent) => {
        event.stopPropagation();
        setUploadStudentIsOpen(false);
        setFiles([]); // Clear the files when the modal is closed
    };

    return (
        <Dialog
            open={uploadStudentIsOpen}
            onClose={handleClose}
            maxWidth='sm'
            fullScreen={fullScreen}
            fullWidth
            PaperProps={{
                sx: { borderRadius: { xs: 0, sm: 2 } }
            }}
            aria-labelledby='uplaod-student-modal-title'
            aria-describedby='upload-student-modal-content'
        >

            <DialogTitle id='upload-students-modal-title' sx={{
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'space-between',
                fontSize: 32,
                fontWeight: 700,
                marginBottom: '16px' // Add margin bottom for spacing
            }}>
                Upload Students
                <IconButton edge="end" color="inherit" onClick={handleClose} aria-label="close">
                    <CloseIcon fontSize="large" />
                </IconButton>
            </DialogTitle>

            <DialogContent id='upload-students-modal-content'>
                <Box>
                    <Box sx={{ pb: 2 }}>
                        <ProductSelect selectedProduct={selectedProduct} onProductChange={setSelectedProduct} />
                    </Box>

                    {/* FUTURE: Only show when product = Grace */}
                    <Box sx={{ pb: 2 }}>
                        <Typography sx={{ fontWeight: 700, pt: 2, pb: 1 }}>Download Template</Typography>
                        <a href="https://dashboard.inceptia.org/wp-content/uploads/2014/08/GRACE-File-Layout1.xlsx" style={{ color: theme.palette.InceptiaGreen.main, textDecoration: 'none' }}>
                            <Typography sx={{ fontWeight: 700 }}>template_grace.csv</Typography>
                        </a>
                    </Box>

                    <Box
                        sx={{
                            display: 'flex',
                            alignItems: 'center',
                            justifyContent: 'center',
                            height: '100px',
                            borderRadius: '8px',
                            backgroundColor: 'grey.100',
                            width: '100%',
                            boxSizing: 'border-box', // Add this line
                        }}
                        {...getRootProps()}
                    >
                        <input {...getInputProps()} />
                        <Typography>
                            {files.length > 0 ? `Selected file - ${files[0].name}` : "Drag & Drop or "}
                            <Link
                                component="button"
                                variant="body1"
                                color="primary"
                                underline="always"
                                onClick={openFileDialog}
                                sx={{ cursor: 'pointer' }}
                            >
                                Browse
                            </Link>
                        </Typography>
                    </Box>
                </Box>
            </DialogContent>



            <DialogActions
                sx={{
                    color: 'InceptiaGreen.main',
                    borderTop: '2px solid',
                    borderColor: 'grey.100',
                    p: 3,
                    justifyContent: 'center',
                }}
            >
                <Grid container rowSpacing={{ xs: 1.5, sm: 2 }} columnSpacing={2}>
                    <Grid item xs={12} md={6}>
                        <Button
                            variant='outlined'
                            size='large'
                            color='inherit'
                            sx={{
                                textTransform: 'none',
                                '&:hover, &:focus': {
                                    backgroundColor: inceptiaGreenAlphaColors.hover
                                },
                                height: '48px', // Increase the height of the button
                                width: '100%' // Take up the entire space
                            }}
                            onClick={handleClose}
                        >
                            Cancel
                        </Button>
                    </Grid>
                    <Grid item xs={12} md={6}>
                        <Button
                            variant='contained'
                            size='large'
                            sx={{
                                textTransform: 'none',
                                bgcolor: 'InceptiaGreen.main',
                                '&:hover': {
                                    backgroundColor: 'InceptiaGreen.dark',
                                },
                                height: '48px', // Increase the height of the button
                                width: '100%' // Take up the entire space
                            }}
                            onClick={handleFileUpload}
                        >
                            Upload Students
                        </Button>
                    </Grid>
                </Grid>
            </DialogActions>

        </Dialog>
    )
};

export default AdminUploadStudentModal
