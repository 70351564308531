import { useContext, useEffect } from 'react';
import { Box, Checkbox, FormControlLabel, MenuItem, Radio, RadioGroup, Typography } from '@mui/material';
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';
import * as yup from 'yup';
import { useFormik } from "formik";
import { AdminFormDataContext } from '../../contexts/AdminFormDataContext';
import { OrganizationContext } from '../../contexts/OrganizationContext';
import { AdminFormCrudProps } from '../../pages/AdminFormCrud';
import { BillingType, BillingTypeLabel, Status, StatusLabel } from '../../utilities/FormEnums';
import CustomTextField from '../CustomTextField';
import useFormikFirstErrorFocus from '../../utilities/useFormikFirstErrorFocus';

export type BillingDataType = {
  billing_Type_Id?: number | null;
  invoice_Status_Ids?: number[] | null;
};

const FormCrudBilling = (props: AdminFormCrudProps) => {
  const { formData, isAdminFormDataLoaded } = useContext(AdminFormDataContext);
  const { isOrganizationDataLoaded } = useContext(OrganizationContext);

  const billingSX = {
    radioGroup: {
      display: 'inline-flex',
      mb: 6.25
    },
    formControlLabel: {
      mr: 0,
      '& .MuiFormControlLabel-label': {
        fontSize: '0.875rem'
      },
      // Don't want the label greyed-out when disabled - just the radio button
      '& .MuiFormControlLabel-label.Mui-disabled': {
        color: 'var(--text-color)'
      }
    }
  };

  const billingFormik = useFormik({
    initialValues: {
      action: props.action,
      billing_Type_Id: BillingType.ANNUAL_SUBSCRIPTION,
      invoice_Status_Ids: [] as number[]
    },
    validationSchema: yup.object({
      action: yup.string(),
      billing_Type_Id: yup.number().oneOf([BillingType.ANNUAL_SUBSCRIPTION, BillingType.FORM_STATUS]),
      invoice_Status_Ids: yup.array().when('billing_Type_Id', (billing_Type_Id, schema) => {
        // Only want the invoice_Status_Ids array to be required IF billing_Type_Id = "By form status(es)"
        if (Number(billing_Type_Id) === Number(BillingType.FORM_STATUS)) {  
          return schema.of(yup.number()).min(1, 'Please select at least one status')
        }
        return schema;
      })
    }),
    onSubmit: values => {
      if (props.action === 'add') {
        if (props && props.setIsBillingDataValid) props.setIsBillingDataValid(true);
        if (props && props.setBillingData) props.setBillingData({
          billing_Type_Id: values.billing_Type_Id,
          invoice_Status_Ids: values.invoice_Status_Ids
        });
      }
    }
  });

  useFormikFirstErrorFocus({ formik: billingFormik });

  useEffect(() => {
    if (props.action !== 'add' && isAdminFormDataLoaded && isOrganizationDataLoaded) {
      billingFormik.setValues({
        action: props.action,
        billing_Type_Id: formData?.billing_Type_Id || BillingType.ANNUAL_SUBSCRIPTION,
        invoice_Status_Ids: formData?.forms_Invoice_Status?.map(item => item.status_Id) || []
      });
    }
  }, [isAdminFormDataLoaded, isOrganizationDataLoaded]);

  useEffect(() => {
    if (props.action === 'add' && props.createFormButtonClicked) {
      saveChanges();
    }
  }, [props.createFormButtonClicked]);

  const saveChanges = async () => {
    // Since this is the last component on the page, we can reset the createFormButtonClicked flag
    if (props.setCreateFormButtonClicked) props.setCreateFormButtonClicked(false);

    billingFormik.submitForm();
  };

  return (<>
    <Typography variant='h2' sx={{ fontSize: '1.5rem', fontWeight: 500, mb: 2 }}>Billing</Typography>

    <Typography variant='body1' id='billing-group-label' sx={{ fontWeight: 500 }}>Choose how you would like to be invoiced for this form</Typography>
    <RadioGroup
      aria-labelledby='billing-group-label'
      name='billing_Type_Id'
      value={billingFormik.values.billing_Type_Id}
      onBlur={billingFormik.handleBlur}
      onChange={e => billingFormik.setFieldValue('billing_Type_Id', Number(e.target.value))}
      sx={{ ...billingSX.radioGroup,
        ...(billingFormik.values.billing_Type_Id === BillingType.FORM_STATUS && { mb: 2 }),
        ...(billingFormik.values.billing_Type_Id === BillingType.ANNUAL_SUBSCRIPTION && { mb: 3.5 })
      }}
    >
      <FormControlLabel
        disabled={props.action === 'edit'}
        value={BillingType.ANNUAL_SUBSCRIPTION}
        control={<Radio />}
        label={BillingTypeLabel.get(BillingType.ANNUAL_SUBSCRIPTION)}
        sx={billingSX.formControlLabel}
      />
      <FormControlLabel
        disabled={props.action === 'edit'}
        value={BillingType.FORM_STATUS}
        control={<Radio />}
        label={BillingTypeLabel.get(BillingType.FORM_STATUS)}
        sx={billingSX.formControlLabel}
      />
    </RadioGroup>

    {billingFormik.values.billing_Type_Id === BillingType.FORM_STATUS && (
      <Box><CustomTextField
        disabled={props.action === 'edit'}
        id='invoice_Status_Ids'
        labelText={props.action === 'edit' ? 'Target statuses' : 'Choose target statuses'}
        select
        inputProps={{
          sx: {
            fontSize: '0.875rem',
            py: '13px'
          }
        }}
        SelectProps={{
          multiple: true,
          // Don't want the checkboxes from the dropdown <MenuItem>s to be displayed in the select box itself
          renderValue: (selected) => (selected as number[]).map(num => StatusLabel.get(num)).join(', ')
        }}
        value={billingFormik.values.invoice_Status_Ids}
        onBlur={() => billingFormik.setFieldTouched('invoice_Status_Ids', true)}
        onChange={e => billingFormik.setFieldValue('invoice_Status_Ids', e.target.value)}
        error={billingFormik.touched.invoice_Status_Ids && Boolean(billingFormik.errors.invoice_Status_Ids)}
        helperText={billingFormik.touched.invoice_Status_Ids && billingFormik.errors.invoice_Status_Ids}
        sx={{
          maxWidth: '385px', // Figma width
          ml: 3.75,
          mb: 5,
          ...(props.action === 'edit' && { mb: 3.5 })
        }}
      >
        {/* Loop through Status enum writing a MenuItem for each (excludes REMOVED) */}
        {Object.values(Status)
          .filter(Number.isInteger)
          .filter(status => status !== Status.REMOVED)
          .map(status => (
            <MenuItem
              key={status}
              value={status}
              sx={{
                fontSize: '0.875rem',
                fontWeight: 500,
                py: 0,
                pl: 0.75,
                pr: 2
              }}
            >
              <Checkbox checked={billingFormik.values.invoice_Status_Ids?.includes(Number(status))} />
              {StatusLabel.get(Number(status))}
            </MenuItem>
          ))}
      </CustomTextField>
    </Box>
    )}

    {props.action === 'edit' && (
      <Typography variant='body2' sx={{ display: 'flex', alignItems: 'center', mb: 4.5 }}>
        <InfoOutlinedIcon sx={{ mr: 0.75, width: '16px' }} />
        To change how you get invoiced for this form, contact your Inceptia representative
      </Typography>
    )}

  </>);
};

export default FormCrudBilling;