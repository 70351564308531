import { useParams } from "react-router-dom";
import BackLink from "./BackLink";
import { 
    Grid, 
    Chip,
    Paper, 
    Typography 
} from "@mui/material";
import { cardSX } from '../utilities/CSS';
import UserSummary from "./UserSummary";
import ServiceHistory from "./ServiceHistory";
import { useEffect, useState } from "react";
import adminFormsService from "../services/AdminFormsService";
import FormSubmission from "./FormSubmission";
import { iUserDTO, iUserServiceHistoryDTO } from "../utilities/APIInterfaces";
import userService from "../services/UserService";
import { OrganizationContextProvider } from "../contexts/OrganizationContext";
import { iAdminFormSubmissionsData } from "../contexts/AdminFormSubmissionsDataContext";
import formsService from "../services/FormsService";

export interface iUserSummary {
    name: string;
    email: string;
    phone: string;
    address: string;
    ssn: string | null;
    dob: string | null;
    studentId: string | null;
}

export default function AdminApplicantForm() {
    const { userId, submissionUuid } = useParams();
    const [ user, setUser ] = useState<iUserDTO>();
    const [form, setForm] = useState({} as any);
    const [submission, setSubmission] = useState<iAdminFormSubmissionsData | undefined>(undefined);
    const [ serviceHistory, setServiceHistory ] = useState<iUserServiceHistoryDTO[]>([]);
    const [isServiceHistoryLoaded, setIsServiceHistoryLoaded] = useState(false);

    useEffect(() => {
      (async() => {
        const result = await userService.GetUser(userId, (x) => x);
        setUser(result);
    
        const submission = await adminFormsService.GetFormSubmisisonByUuid(submissionUuid ?? '', (v: boolean) => {});
        setSubmission(submission);

        const form = await formsService.GetFormByUuid(submission?.form_Uuid ?? '', (x) => x);
        setForm(form);
      })();
    }, []);

    useEffect(() => {
      if ( ! isServiceHistoryLoaded) {
        userService.GetUserServiceHistory(userId, submissionUuid ?? '', (x) => x)
          .then(result => setServiceHistory(result))
          .finally(() => setIsServiceHistoryLoaded(true));
      }
    }, [isServiceHistoryLoaded]);
    
    return (
        <OrganizationContextProvider>
            <BackLink title='Back to Form Submissions'/>
            <Typography variant='h1' sx={{ fontWeight: '600', fontSize: '1.5rem', mb: 1.75 }}>
                {`${user?.first_Name} ${user?.last_Name}`}
                <Chip
                  label='Student'
                  variant='outlined'
                  sx={{
                    backgroundColor: '#fff',
                    borderColor: '#e1e2e5',
                    ml: 1.75,
                    px: 2,
                    '& .MuiChip-label': {
                      fontSize: '0.875rem',
                      fontWeight: 'normal',
                      p: 0
                    }
                  }}
                />
            </Typography>

            <Grid container spacing={3.75} sx={{ mb: 5 }}>
              <Grid item xs={12} lg={6}>
                <Paper sx={{...cardSX.paperMinHeight, px: 3.25, py: 3.75}}>
                  <UserSummary user={user}/>
                </Paper>
              </Grid>
              <Grid item xs={12} lg={6}>
                <Paper sx={{...cardSX.paperMinHeight, px: 3.25, py: 3.75}}>
                  <ServiceHistory
                    userId={userId || ''}
                    setIsServiceHistoryLoaded={setIsServiceHistoryLoaded}
                    submissionUuid={submissionUuid || ''}
                    serviceHistory={serviceHistory}
                  />
                </Paper>
              </Grid> 
            </Grid>

            <Typography variant='h2' sx={{ fontWeight: '600', fontSize: '1.5rem', mb: 2 }}> 
              Form Submission - {form.name}
            </Typography>
 
            <Paper sx={{...cardSX.paper, p: '1px' }}>
              {submission !== undefined && Object.keys(form).length > 0 && <FormSubmission
                form={form}
                submission={submission}
                setSubmission={setSubmission}
                setIsServiceHistoryLoaded={setIsServiceHistoryLoaded}
                verifier_Type_Id={form.verifier_Type_Id}
              />}
            </Paper>
        </OrganizationContextProvider>
    )
}