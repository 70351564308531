import { createContext, useContext, useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { useSnackbar } from 'notistack';
import { OrganizationContext } from '../contexts/OrganizationContext';
import adminFormsService from '../services/AdminFormsService';
import { iFormsInvoiceStatusDTO, iTagDTO } from '../utilities/APIInterfaces';

export interface iAdminFormData {
    readonly [index: string]: number | string | iTagDTO[] | iFormsInvoiceStatusDTO[] | null;
    id: number;
    uuid: string;
    name: string | null;
    description: string | null;
    organization_Id: number | null;
    institution: string | null;
    submissions: number | null;
    visibility: string | null;
    verification: string | null;
    priority: string | null;
    start_Date: string | null;
    end_Date: string | null;
    due_Date: string | null;
    version_Number: number | null;
    aid_Year: string | null;
    category: string | null;
    tags: iTagDTO[] | null;
    billing_Type_Id: number | null;
    forms_Invoice_Status: iFormsInvoiceStatusDTO[] | null;

    // Calculated field for the admin forms table (Status column)
    activeStatus: string;
}

export type AdminFormDataContextType = {
  formData: iAdminFormData | null | undefined;
  setFormData: (Form: iAdminFormData | null) => void;
  formsData: iAdminFormData[] | null | undefined;
  setFormsData: (Forms: iAdminFormData[] | null) => void;
  isAdminFormDataLoaded: boolean;
  setIsAdminFormDataLoaded: (value: boolean) => void;
}

export const AdminFormDataContext = createContext<AdminFormDataContextType>({
  formData: null,
  setFormData: formData => console.warn('no form provider'),
  formsData: null,
  setFormsData: formsData => console.warn('no form provider'),
  isAdminFormDataLoaded: false,
  setIsAdminFormDataLoaded: value => console.warn('no form provider')
});
export const useFormsData = () => useContext(AdminFormDataContext);

interface FormsContextProps {
  children?: React.ReactNode
}

export const AdminFormDataContextProvider: React.FC<FormsContextProps> = ({ children }) => {
  const [formData, setFormData] = useState<iAdminFormData | null>(null);
  const [formsData, setFormsData] = useState<iAdminFormData[] | null>([]);
  const [isAdminFormDataLoaded, setIsAdminFormDataLoaded] = useState(false);
  const { enqueueSnackbar } = useSnackbar();
  const { formUuid } = useParams();
  const organization = useContext(OrganizationContext);
  const currentopeid = organization.organization?.opeid;

  useEffect(() => {
    async function GetAdminFormListDataAsync(opeId: string | undefined) {
      if (opeId) {
        await adminFormsService.GetAdminFormListData(opeId, (v) => v)
          .then((result) => {
            if (result) {
              const adminFormListData = result as iAdminFormData[];
              const currentDate = new Date();
              adminFormListData.forEach(form => {
                if (form.start_Date && form.end_Date) {
                  // If current date is between start and end date, the form's status is Active
                  if (currentDate >= new Date(form.start_Date) && currentDate <= new Date(form.end_Date)) {
                    form.activeStatus = 'Active';
                  } else {
                    form.activeStatus = 'Inactive';
                  }
                } else {
                  form.activeStatus = 'N/A';
                }
              });
              // Get individual form data if formUuid is provided in the URL
              if (formUuid) {
                const form = adminFormListData.find(form => form.uuid === formUuid);
                if (form) setFormData(form);
              }
              setFormsData(adminFormListData);
            } else {
              setFormData(null);
              setFormsData([]);
            }
          })
          .catch((error) => {
            enqueueSnackbar(error.toString());
            console.log("GetAdminFormListDataAsync 1", error);
          }).finally(() => {
            setIsAdminFormDataLoaded(true);
          });
      }
    }

    if (currentopeid) GetAdminFormListDataAsync(currentopeid);
  }, [enqueueSnackbar, currentopeid, isAdminFormDataLoaded, formUuid]);

  return (
    <AdminFormDataContext.Provider value={{ formData, setFormData, formsData, setFormsData, isAdminFormDataLoaded, setIsAdminFormDataLoaded }}>
      {children}
    </AdminFormDataContext.Provider>
  );
}