export interface iUserDTO {
  id: number | null;
  first_Name: string;
  last_Name: string;
  last4: string | null;
  email: string;
  phone: string;
  auth_Id: string;
  ssn: string | null;
  dob: string | null;
  sms_Consent: boolean;
  ssn_Dob_Set: boolean;
  studentSchools: iStudentSchoolDTO[];
  mfaOptions: iKeycloakOption[];
}

export interface iUserServiceHistoryDTO {
  id: number;
  source: string | null;
  type: number;
  description: string | null;
  body: string | null;
  action_Date: string | null;
  commenter_Name: string | null;
  commenter_Id: number | null;
}

export interface iUserServiceHistoryCommentDTO {
  id: number;
  auth_Id: string;
  commenter_Id: number;
  submission_Forms_Id: number;
  submission_Forms_Uuid: string;
  created_On: string;
  comment: string;
}

export interface iKeyCloakUserRequestDTO {
  id: string;
  firstName: string;
  lastName: string;
  email: string;
  orgId: string;
  groups: string[];
  productIdList: number[];
}

export interface iStudentSchoolDTO {
  id: number | null;
  user_Id: number | null;
  school_Student_Id: string | null;
  school_Name: string | null;
  school_Id: number | null;
  purl: string | null;
}

export interface iOrgDTO {
  id: number;
  org_Type_Id: number;
  name: string;
  city: string | null;
  state: string | null;
  address: string | null;
  address2: string | null;
  zip: string | null;
  opeid: string;
  source_Id: number;
  last_Update_Date: Date;
  add_Date: Date;
  purl: string;
}

export interface iProductDTO {
  id: number;
  name: string;
  code: string;
}

export interface iSubmission {
    id: number | null;
    uuid: string | undefined;
    formsVersionId: number;
    orgId: number;
    submissionTypeId: number | null;
    statusId: number | null;
    //this field is never used in an update, only in a create, so I'm going to use it in the API to determine if the logged in user is a contributor or not
    submittedBy: number;
    submissionJson: string;
    documents: iDocumentDTO[];
    isRetakingForm: boolean;
    isSubmittingForm: boolean;
}

export interface iTagDTO {
  id: number;
  tag_Name: string;
  org_Id: number;
}

export interface iAdminTag {
  id: number;
  tag_Name: string;
  forms_Using: number;
  submissions_Using: number;
}

export interface iFormTag {
  id: number;
  tag_Name: string;
  org_Id: number;
  forms_Id: number;
}

export interface iFormsInvoiceStatusDTO {
  id: number;
  forms_Id: number;
  status_Id: number;
  created_On: string;
}

export interface iDocumentDTO {
  id: number;
  uuid: string;
  file_Type_Id: number | null;
  file_Path: string | null;
  file_Name: string;
  original_File_Name: string;
  upload_Date: string | null;
  user_Id: number;
}

export interface iTodo {
  formId: number;
  formUuid: string;
  formVersionId: number;
  formVersionUuid: string;
  orgId: number;
  organization: string | null;
  name: string;
  description: string | null;
  priorityId: number;
  taskTypeId: number | null;
  statusId: number | null;
  dueDate: string | null;
  formSubmissionId: number | null;
  formSubmissionUuid: string;
  submissionTypeId: number | null;
  formJson: string;
  canRetake: boolean;
  ownedBy: number;
}

export interface iUserLink {
  Id: number;
  userId?: number | null;
  contributorId?: number | null;
  contributorName?: string | null;
  contributorEmail?: string | null;
  userLinkUUID: string;
  username?: string | null;
  status: string;
  statusDate?: Date | null;
  createdOn: Date;
}

export class AdminRoleNodes {
  admin?: React.ReactNode | null;
  institutionAdmin?: React.ReactNode | null;
  institution?: React.ReactNode | null;
  srOperations?: React.ReactNode | null;
  operations?: React.ReactNode | null;
  manager?: React.ReactNode | null;
}

export class iKeycloakOption {
  id: string = ""
  type: string = ""
}