import { useContext, useEffect } from 'react';
import { Dialog, DialogContent, DialogTitle, IconButton, Typography } from '@mui/material';
import { useMediaQuery, useTheme } from '@mui/material';
import { visuallyHidden } from '@mui/utils';
import CloseIcon from '@mui/icons-material/Close';
import { CurrentFormContext } from './ToDoList';
import { Model } from 'survey-core';
import { Survey } from 'survey-react-ui';
import 'survey-core/defaultV2.min.css';
import { InceptiaLight } from '../themes/surveyjs-inceptia-theme';
import formsService from "../services/FormsService";
import { iDocumentDTO, iSubmission, iTodo } from '../utilities/APIInterfaces';
import { AutoSaveState, Status } from '../utilities/FormEnums';
import { UserContext } from '../contexts/UserContext';
import { useSnackbar } from 'notistack';
import userService from '../services/UserService';
import KeycloakService from '../services/KeycloakService';
import { handleClearFiles, handleUploadFiles } from '../utilities/SurveyJS';

type OpenFormModalProps = {
  openForm: iTodo | null;
  survey: Model;
  setTodoListView: React.Dispatch<React.SetStateAction<'active' | 'completed'>>;
};

let autoSaveState : AutoSaveState = AutoSaveState.INITIAL;
let isRetakingForm = false;
let switchToActiveTab = false;
let submissionId: number | undefined | null;

export default function OpenFormModal(props : OpenFormModalProps) {
  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down('md'));
  props.survey.applyTheme(InceptiaLight);

  const userContext = useContext(UserContext);
  const { openFormIsOpen, setOpenFormIsOpen } = useContext(CurrentFormContext);
  const { setOpenContributorDialog } = useContext(CurrentFormContext);
  let submissionDocuments: iDocumentDTO[] = [];
  let isContributor = props.openForm?.ownedBy != userContext.user?.id
  const HIDE_FOR_PARENT = "HIDE_FOR_PARENT";
  const HIDE_FOR_STUDENT = "HIDE_FOR_STUDENT";
  let hidePanelsFor = isContributor ? HIDE_FOR_PARENT : HIDE_FOR_STUDENT; 
  

  const { enqueueSnackbar } = useSnackbar();
  
  function OnSurveyCommitHandler(sender : any, options: any) {
    const data = props.survey.data;
    data.pageNo = props.survey.currentPageNo;
    autoSaveState = AutoSaveState.DATA_NOT_CHANGED;

    SaveSurveyDataAsync(
      {
        id: submissionId || -1,
        uuid: props.openForm?.formSubmissionUuid,
        formsVersionId: props.openForm?.formVersionId || -1,
        submittedBy: userContext.user?.id || -1,
        submissionJson: JSON.stringify(data),
        submissionTypeId: props.openForm?.submissionTypeId || 1, // default to student
        orgId: props.openForm?.orgId || -1,
        statusId: Status.SUBMITTED,
        documents: submissionDocuments,
        isRetakingForm: isRetakingForm,
        isSubmittingForm: true
      }).then((result) => {
        autoSaveState = AutoSaveState.INITIAL;
        if (result?.statusId == Status.PARENT_INFO_NEEDED) {
          setOpenFormIsOpen(false);
          setOpenContributorDialog(true);
        } 
        else{
          if (switchToActiveTab) props.setTodoListView('active');
          setOpenFormIsOpen(false);
        }
      });
    
  };

  function SurveyDataChanged () {
    if (autoSaveState !== AutoSaveState.LOADING) {
      autoSaveState = AutoSaveState.SAVE_NEEDED;
    }
  }

  function SaveSurveyData () {

    if (autoSaveState === AutoSaveState.SAVE_NEEDED) {
      if (isRetakingForm) switchToActiveTab = true;
      const data = props.survey.data;
      data.pageNo = props.survey.currentPageNo;
      autoSaveState = AutoSaveState.DATA_NOT_CHANGED;

      SaveSurveyDataAsync(
      {
        id: submissionId || -1,
        uuid: props.openForm?.formSubmissionUuid,
        formsVersionId: props.openForm?.formVersionId || -1,
        submittedBy: userContext.user?.id || -1,
        submissionJson: JSON.stringify(data),
        submissionTypeId: props.openForm?.submissionTypeId || 1, // default to student
        orgId: props.openForm?.orgId || -1,
        statusId: Status.INCOMPLETE,
        documents: submissionDocuments,
        isRetakingForm: isRetakingForm,
        isSubmittingForm: false
      });
    }
  }

  async function GetSurveyDataAsync() {

    // If form's status is declined or ineligible and the forms_version.CAN_RETAKE field is true, open the form "fresh" (without the previous content).
    if (props.openForm?.statusId && props.openForm.canRetake && [Status.DECLINED, Status.INELIGIBLE].includes(props.openForm.statusId)) {
      props.survey.data = null;
      props.survey.currentPageNo = 0;
      autoSaveState = AutoSaveState.DATA_NOT_CHANGED;
      isRetakingForm = true;
      return null;
    }


    let authId = userContext.user?.authId?.toString() || "";

    autoSaveState = AutoSaveState.LOADING;

    userService.GetUserSubmission(
      authId,
      props.openForm?.formSubmissionUuid || "",
      (v) => v
      ).then((result) => {
        if (result) {
        let origSubmissionData:iSubmission = result;
        submissionDocuments = origSubmissionData.documents;
        let data = JSON.parse(origSubmissionData.submissionJson);
        props.survey.data = data;
        submissionId = origSubmissionData.id;
        if (data.pageNo) {
          props.survey.currentPageNo = data.pageNo;
        }
        } else {
          props.survey.data = null;
          props.survey.currentPageNo = 0;
          submissionId = null;
        }
        //don't auto populate if the user is a contributor 
        if(!isContributor){
          autoPopulateIf(props.survey);
        }
        //hide the panels that are not needed
        //for some reason .visible isn't accessible without setting the type to any
        props.survey.clearInvisibleValues = false;
        props.survey.getAllPanels().map((panel: any) => {
          if (panel.name.includes(hidePanelsFor)) {
            panel.visible = false;
          }
        });
        autoSaveState = AutoSaveState.DATA_NOT_CHANGED;
        return result;
      })
  }

  async function SaveSurveyDataAsync(newSubmissionData : iSubmission) : Promise<iSubmission | null> {
    return await formsService.SaveFormSubmission(newSubmissionData, (v) => v)
      .then((result) => {
        submissionId = result?.id;
        return result;
      })
      .catch((error) => {
        enqueueSnackbar(error.toString());
        return null;
      }).finally(() => {
        isRetakingForm = false;
      });
  }

  props.survey.onUploadFiles.add(async (_, options) => {
    if (userContext.user){
      submissionDocuments = await handleUploadFiles(userContext.user?.authId!, userContext.user?.authId!, options, submissionDocuments, enqueueSnackbar);
    }
  });

  props.survey.onClearFiles.add(async (_, options) => {
    submissionDocuments = await handleClearFiles(userContext.user?.authId!, options, submissionDocuments, enqueueSnackbar);
  });

  function autoPopulateIf(surveyObj: Model) {
    const mapping = formsService.GetAutofillMapBy(userContext?.user, props.openForm?.orgId ?? 0);
    Object.entries(mapping).map((kvp) => {
      let question = surveyObj.getQuestionByName(kvp.at(0) ?? "");
      if (question) {
        question.value = kvp.at(1) ?? "";
      }
    });
  }

  async function onLoadedSurveyFromServiceHandler() {
    GetSurveyDataAsync();
  }

  props.survey.onLoadedSurveyFromService.add(onLoadedSurveyFromServiceHandler);
  props.survey.onComplete.add(OnSurveyCommitHandler);
  props.survey.onValueChanged.add(SurveyDataChanged);
  props.survey.onCurrentPageChanged.add(SaveSurveyData);

  useEffect(() => {

    if (openFormIsOpen && autoSaveState === AutoSaveState.INITIAL) {
      GetSurveyDataAsync();
    }
  }, [openFormIsOpen]);

    
  const handleClose = () => {
    if (autoSaveState === AutoSaveState.SAVE_NEEDED) switchToActiveTab = true;
    
    // if the handler fires more than once
    SaveSurveyData();
    setOpenFormIsOpen(false);
    autoSaveState = AutoSaveState.INITIAL;
  
    if (switchToActiveTab) props.setTodoListView('active');
  }

  return (
    <Dialog
      open={openFormIsOpen}
      onClose={handleClose}
      maxWidth='lg'
      fullScreen={fullScreen}
      PaperProps={{
        sx: { borderRadius: { xs: 0, md: 2 }}
      }}
      sx={{
        height: { xs: '100%', md: 'initial' },
        "& .MuiDialog-container": {
          "& .MuiPaper-root": {
            width: {sx: "100%", md: "90%" },
            maxWidth: "1175px",
          },
        },        
      }}
      aria-labelledby='form-modal-title'
      aria-describedby='form-modal-content'
    >
      <DialogTitle id='form-modal-title' sx={{ p: 1, display: 'flex' }}>
        {/* Fix "Empty header" accessibility error by adding a "visually hidden" element for the dialog title */}
        <Typography variant='body1' sx={visuallyHidden}>Open Form Modal</Typography>
        <IconButton aria-label='Close' onClick={handleClose} sx={{ ml: 'auto' }}>
          <CloseIcon />
        </IconButton>
      </DialogTitle>

      <DialogContent id='form-modal-content' sx={{
        mb: 0,
        p: 0,

        // Hide the "Clear" (eraser icon) and "Remove this file" options for students
        // Don't want students to delete files that have been uploaded
        ...(KeycloakService.getGroups().length === 0 ? {
          '& #sv-file-clean': {
            display: 'none'
          },
          '& .sd-file__preview-item .sd-file__remove-file-button': {
            display: 'none !important'
          }
        } : {})
      }}>
        <Survey model={props.survey} />
      </DialogContent>
    </Dialog>
  )
}
