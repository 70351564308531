import { useContext, useState, useEffect } from 'react';
import { Container, Typography, TextField, Button, Grid, Box, Chip, Skeleton } from '@mui/material';
import AccountCircleIcon from '@mui/icons-material/AccountCircle';
import { BackToUsersLink } from  '../AdminCenterUsers';
import { pageHeaderSX } from '../../utilities/CSS';
import { AdminUsersDataContext } from '../../contexts/AdminUsersDataContext';
import KeycloakService from '../../services/KeycloakService';
import { inceptiaGreenAlphaColors } from '../../utilities/CSS';
import DashboardAPI from '../../apis/DashboardAPI';
import UserService from '../../services/UserService';
import { useParams } from 'react-router-dom';
import { iUserDTO } from '../../utilities/APIInterfaces';
import { MfaType } from '../../utilities/FormEnums';

const UserEdit = () => {
    const [isEditable, setIsEditable] = useState(false);
    const { userData, isAdminUsersDataLoaded } = useContext(AdminUsersDataContext); // 
    const [currentUserData, setCurrentUserData] = useState<iUserDTO>(); // Define currentUserData as a state variable with type 'any'

    const [email, setEmail] = useState('');
    const [originalEmail, setOriginalEmail] = useState(''); // Store original email to revert back to if needed
    const [isEmailEditable, setIsEmailEditable] = useState(false);

    const [phone, setPhoneNumber] = useState('');
    const [originalPhoneNumber, setOriginalPhoneNumber] = useState(''); // Store original mobile number to revert back to if needed
    const [isMobileEditable, setIsMobileEditable] = useState(false);

    const [loading, setLoading] = useState(true);
    const [refreshData, setRefreshData] = useState(false); // State to trigger useEffect


    const containerSX = {
        p: {
            xs: 2.25,
            sm: 4.5,
            backgroundColor: 'white',
            borderRadius: '8px',
        }
    };

    //console.log("userData", userData);
    // use authId to call GetUserAsync from UserDB.cs in API 

    useEffect(() => {
        const fetchPhoneNumber = async () => {
            if (isAdminUsersDataLoaded && userData?.authId) {
                
                try {
                    const currentUserDataAsync: any = await DashboardAPI.callForData({ route: `User/${userData.authId}` });
                    // Assuming the phone number is part of the returned data
                    setPhoneNumber(currentUserDataAsync.phone);
                    setCurrentUserData({ ...currentUserDataAsync }); // Update currentUserData state
                    setEmail(currentUserDataAsync.email);
                    setOriginalEmail(currentUserDataAsync.email);
                } catch (error) {
                    console.error('Failed to fetch user data', error);
                } finally {
                    setLoading(false);
                }
            } else {
                console.log("Data not loaded yet");
            }
        };

        fetchPhoneNumber();
    }, [isAdminUsersDataLoaded, userData?.authId, refreshData]);


    const editEmail = (e: React.ChangeEvent<HTMLInputElement>) => {
        setEmail(e.target.value);
    }

    const editPhoneNumber = (e: React.ChangeEvent<HTMLInputElement>) => {
        setPhoneNumber(e.target.value);
    }

    // Function to toggle editable state
    const toggleEditable = (field: string) => {
        switch (field) {
            case 'email':
                if (isEmailEditable) {
                    // Revert to original email if canceling
                    setEmail(originalEmail);
                } else {
                    // Store the current email as original before editing
                    setOriginalEmail(email);
                }
                setIsEmailEditable(!isEmailEditable);
                break;
            case 'mobile':
                if (isMobileEditable) {
                    // Revert to original phone number if canceling
                    setPhoneNumber(originalPhoneNumber);
                } else {
                    // Store the current phone number as original before editing
                    setOriginalPhoneNumber(phone);
                }
                setIsMobileEditable(!isMobileEditable);
                break;
            default:
                break;
        }
    };

    const prepareUserData = () => {
        return {
            ...currentUserData,
            email,
            phone
        };
    };

    const updateUserData = async (newDataUserData: any) => {
        try {
            if (currentUserData) {
                const response = await UserService.UpdateUser(currentUserData, newDataUserData, (v) => v);
                console.log('response', response);
            } else {
                console.error('Failed to update user data: currentUserData is undefined');
            }
        } catch (error) {
            console.error('Failed to update user data', error);
        }
    };

    const handleSaveChanges = async () => {
        if (currentUserData && email === currentUserData.email && phone === currentUserData.phone) {
            console.log('No changes detected');
            return;
        }

        console.log('Changes detected');
        const newDataUserData = prepareUserData(); 
        console.log('New user data:', newDataUserData);

        await updateUserData(newDataUserData);
        
        setIsEmailEditable(false);
        setIsMobileEditable(false);
        setRefreshData(prev => !prev); // Toggle refreshData to trigger useEffect
    };

    return (<>
        {/* Loading... */}

        {!isAdminUsersDataLoaded && (<>
            <Skeleton animation='pulse' variant='text' sx={{ height: '20px', mt: 0.5, mb: 1.25, transform: 'none' }} width={150} />
            <Skeleton animation='pulse' variant='text' sx={{ height: '37px', mb: 2.25, transform: 'none' }} width={400} />
        </>)}

        {/* Loaded, with selected form */}
        {isAdminUsersDataLoaded && userData && (<>
            <BackToUsersLink />
            <Box sx={{ display: 'flex', alignItems: 'center', mb: 1 }}>
                <Typography variant='h1' sx={{ ...pageHeaderSX }}>{userData.name}</Typography>
                <Chip
                    label="Admin"
                    sx={{
                        m: 2,
                        fontWeight: 'bold',
                        backgroundColor: 'white',
                        border: '1px solid #ccc',
                        boxShadow: '0 2px 4px rgba(0, 0, 0, 0.1)'
                    }}
                />
            </Box>

            <Container sx={containerSX}>
                <Grid container rowSpacing={{ xs: 1.5, sm: 2 }} columnSpacing={2}>
                    <Grid item xs={12} md={12}>
                        <Typography variant="h1" sx={{ ...pageHeaderSX, mb: 2.25 }}>Basic Info</Typography>
                    </Grid>
                    <Grid item xs={12} md={12}>
                        <Typography>Profile Image</Typography>
                    </Grid>
                    <Grid item xs={12} md={12}>
                        <AccountCircleIcon sx={{ fontSize: 150, mb: 2 }} />
                    </Grid>
                    <Grid item xs={12} md={6}>
                        <TextField label="First Name" value={userData.firstname} fullWidth margin="normal" InputProps={{ readOnly: !isEditable }} />
                    </Grid>
                    <Grid item xs={12} md={6}>
                        <TextField label="Last Name" value={userData.lastName} fullWidth margin="normal" InputProps={{ readOnly: !isEditable }} />
                    </Grid>
                    <Grid item xs={12} md={6}>
                        <TextField
                            label="Email Address"
                            value={email || ''}
                            fullWidth
                            margin="normal"
                            onChange={editEmail}
                            disabled={!isEmailEditable}
                        />
                        <Typography
                            variant="body2"
                            color="primary"
                            sx={{ cursor: 'pointer', fontWeight: 'bold', fontSize: '14px' }}
                            onClick={() => toggleEditable('email')}
                        >
                            {isEmailEditable ? 'Cancel' : 'Edit'}
                        </Typography>
                    </Grid>
                    <Grid item xs={12} md={6}>
                        <TextField
                            label="Mobile Number"
                            value={phone || ''}
                            fullWidth
                            margin="normal"
                            onChange={editPhoneNumber}
                            disabled={!isMobileEditable}
                            inputProps={{ inputMode: 'numeric', pattern: '[0-9]*' }} // Restrict input to numbers
                            placeholder={isMobileEditable ? '' : 'Mobile Number'} // Set placeholder conditionally
                        />
                        <Typography
                            variant="body2"
                            color="primary"
                            sx={{ cursor: 'pointer', fontWeight: 'bold', fontSize: '14px' }}
                            onClick={() => toggleEditable('mobile')}
                        >
                            {isMobileEditable ? 'Cancel' : 'Edit'}
                        </Typography>
                    </Grid>
                    <Grid item xs={12} md={12}>
                        <Button
                            variant="contained"
                            sx={{
                                mt: 4,
                                height: '45px',
                                width: '183px',
                                bgcolor: 'InceptiaGreen.main',
                                '&:hover': {
                                    backgroundColor: 'InceptiaGreen.dark',
                                },
                            }}
                            onClick={handleSaveChanges} // Add onClick handler
                        >
                            Save Changes
                        </Button>
                    </Grid>
                </Grid>
            </Container>
        </>)}



    </>
    );
};

export default UserEdit;