import React, { createContext, useContext, useEffect, useState } from 'react';
import { useSnackbar } from 'notistack';
import { OrganizationContext } from '../contexts/OrganizationContext';
import AdminUsersService from '../services/AdminUsersService';
import { useParams } from 'react-router-dom';

export interface iAdminUserData {
    [index: string]: string | string[] | undefined;
    userId: string;
    authId: string;
    name: string;
    email: string;
    productId: string[];
    role: string;
    status: string;
}

export type AdminUsersDataContextType = {
    userData: iAdminUserData | null | undefined;
    setUserData: (User: iAdminUserData | null) => void;
    usersData: iAdminUserData[] | null | undefined;
    setUsersData: (Users: iAdminUserData[] | null) => void;
    isAdminUsersDataLoaded: boolean;
    setIsAdminUsersDataLoaded: (value: boolean) => void;
}

export const AdminUsersDataContext = createContext<AdminUsersDataContextType>({
    userData: null,
    setUserData: userData => console.warn('no user'),
    usersData: null,
    setUsersData: usersData => console.warn('no user'),
    isAdminUsersDataLoaded: false,
    setIsAdminUsersDataLoaded: value => console.warn('no user')
});

export const useUsersData = () => useContext(AdminUsersDataContext);

interface UsersContextProps {
    children: React.ReactNode;
}

export const AdminUsersDataContextProvider: React.FC<UsersContextProps> = ({ children }) => {
    const [userData, setUserData] = useState<iAdminUserData | null>(null);
    const [usersData, setUsersData] = useState<iAdminUserData[] | null>([]);
    const [isAdminUsersDataLoaded, setIsAdminUsersDataLoaded] = useState(false);
    const { enqueueSnackbar } = useSnackbar();
    const { organization } = useContext(OrganizationContext);
    const { authId } = useParams();

    useEffect(() => {
        async function GetAdminUsersListDataAsync(orgId: string | undefined) {
            if (orgId) {
                await AdminUsersService.GetInstitutionUsers(orgId, (v) => v)
                    .then(result => {
                        if (result) {
                            const adminUsersListData = (result as iAdminUserData[]).map(user => ({
                                ...user,
                                name: `${user.firstname} ${user.lastName}`,
                                status: 'Active' // FUTURE: Remove hardcoded value when status is available in the API

                            }));

                            if (authId) {
                                const user = adminUsersListData.find(user => user.authId === authId);
                                if (user) setUserData(user);
                            }
                            setUsersData(adminUsersListData);
                        }
                        else {
                            setUserData(null);
                            setUsersData([]);
                        }
                    })
        .catch(error => {
            enqueueSnackbar(error.toString(), { variant: 'error' });
            console.log("GetAdminUsersListDataAsync 1", error);
        }).finally(() => {
            setIsAdminUsersDataLoaded(true);
        });
    }
    }

if (organization?.id) {
    GetAdminUsersListDataAsync(organization.id.toString());
}
    }, [organization, enqueueSnackbar]);


return (
    <AdminUsersDataContext.Provider value={{ userData, setUserData, usersData, setUsersData, isAdminUsersDataLoaded, setIsAdminUsersDataLoaded }}>
        {children}
    </AdminUsersDataContext.Provider>
);

    
}
