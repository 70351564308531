import DashboardAPI from "../apis/DashboardAPI";
import { iUser } from "../contexts/UserContext";
import { iSubmission } from "../utilities/APIInterfaces";
import KeycloakService from "./KeycloakService";

class FormsService {
    
    async GetToDoListData(authId: string | undefined, loader: (v: boolean) => void) {
        let id = KeycloakService.getId();
        console.log(id);
        let formData = null;
        if(id != null) {
            loader(true);
            formData = await DashboardAPI.callForData({
              method: "GET",
              route: `todo/${id}`});
            loader(false); 
        }

        return formData;
    }

    async SaveFormSubmission(submissionData: iSubmission, loader: (v: boolean) => void) : Promise<iSubmission | null>
    {
        let newSubmissionData: iSubmission | null = null;
        if(submissionData !== undefined) { //coersion with != intentional to account for null
            loader(true);
            newSubmissionData = await DashboardAPI.callForData({
              method: "POST",
              route: "submissionform",
              body: submissionData});
            loader(false)
        }

        return newSubmissionData;
    }
    async GetFormByUuid(formUuid: string, loader: (v: boolean) => void) {
        let formData = null;
        loader(true);
        formData = await DashboardAPI.callForData({
          method: "GET",
          route: `form/${formUuid}`});
        loader(false); 

        return formData;
    }

    GetAutofillMapBy(user: iUser | null | undefined, orgId: number) {
      return user ? { 
        "FIRST_NAME_AUTOFILL": user?.firstName,
        "LAST_NAME_AUTOFILL": user?.lastName,
        "DOB_AUTOFILL": user?.dob?.split("T").at(0),
        "LAST_FOUR_SSN_AUTOFILL": user?.last4,
        "PHONE_NUMBER_AUTOFILL": user?.phone,
        "EMAIL_AUTOFILL": user?.email,
        "STUDENT_ID_AUTOFILL": user?.schools.find((s) => s.school_Id === orgId)?.school_Student_Id,
        "FIRST_NAME_AUTOFILL_ALWAYSEDIT": user?.firstName,
        "LAST_NAME_AUTOFILL_ALWAYSEDIT": user?.lastName,
        "DOB_AUTOFILL_ALWAYSEDIT": user?.dob?.split("T").at(0),
        "LAST_FOUR_SSN_AUTOFILL_ALWAYSEDIT": user?.last4,
        "PHONE_NUMBER_AUTOFILL_ALWAYSEDIT": user?.phone,
        "EMAIL_AUTOFILL_ALWAYSEDIT": user?.email,
        "STUDENT_ID_AUTOFILL_ALWAYSEDIT": user?.schools.find((s) => s.school_Id === orgId)?.school_Student_Id
      } : {};
    }
}

const formsService = new FormsService()
export default formsService;