/* tslint:disable */
/* eslint-disable */
/**
 * notification-api
 * No description provided (generated by Swagger Codegen https://github.com/swagger-api/swagger-codegen)
 *
 * OpenAPI spec version: 1.2
 * 
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */

/**
 * The type of operation to perform.
 * @export
 * @enum {string}
 */
export enum OperationTypeEnum {
    EQUALS = 'EQUALS',
    LIKE = 'LIKE',
    LIKELEFT = 'LIKE_LEFT',
    LIKERIGHT = 'LIKE_RIGHT',
    GT = 'GT',
    LT = 'LT',
    GTE = 'GTE',
    LTE = 'LTE'
}

