import UserEdit from '../components/admin/UserEdit';
import { AdminUsersDataContextProvider } from '../contexts/AdminUsersDataContext';

export type AdminUserCrudActionType = 'add' | 'edit';

export type AdminUserCrudProps = {
    action: AdminUserCrudActionType;

};

export default function AdminUserCrud({ action }: { action: AdminUserCrudActionType }) {
    return (
        <AdminUsersDataContextProvider>
            {action === 'add' ?
                <UserEdit />
                :
                <UserEdit />
            }
        </AdminUsersDataContextProvider>
    )
}