import DashboardAPI from '../apis/DashboardAPI';
import { NotificationData, NotificationScheduleDto, NotificationTemplateDto, PaginatedResultNotificationSchedule, PaginatedResultNotificationTemplate, SearchRequest } from '../apis/notifications';
import { NotificationSendResult } from '../apis/notifications/models/notification-send-result';

class NotificationService {
    async SendNotification(body: Array<NotificationData>): Promise<NotificationSendResult> {
        let result = await DashboardAPI.callForData<NotificationSendResult>({
            method: "POST",
            route: "notification/send",
            body: body
        });
        return result;
    }

    async SearchTemplates(body: SearchRequest): Promise<NotificationTemplateDto[]> {
        let result = await DashboardAPI.callForData<PaginatedResultNotificationTemplate>({
            method: "POST",
            route: "notification/template/search",
            body: body
        });
        return result.data ?? [];
    }

    async CreateTemplate(body: NotificationTemplateDto): Promise<NotificationTemplateDto> {
        let result = await DashboardAPI.callForData<NotificationTemplateDto>({
            method: "POST",
            route: "notification/template",
            body: body
        });
        return result;
    }

    async UpdateTemplate(id: number, body: NotificationTemplateDto): Promise<NotificationTemplateDto> {
        let result = await DashboardAPI.callForData<NotificationTemplateDto>({
            method: "PATCH",
            route: `notification/template/${id}`,
            body: body
        });
        return result;
    }

    async DeleteTemplate(id: number): Promise<void> {
        await DashboardAPI.call({
            method: "DELETE",
            route: `notification/template/${id}`
        });
    }

    async SearchSchedules(body: SearchRequest): Promise<NotificationScheduleDto[]> {
        let result = await DashboardAPI.callForData<PaginatedResultNotificationSchedule>({
            method: "POST",
            route: "notification/schedule/search",
            body: body
        });
        return result.data ?? [];
    }

    async CreateSchedule(body: NotificationScheduleDto): Promise<NotificationScheduleDto> {
        let result = await DashboardAPI.callForData<NotificationScheduleDto>({
            method: "POST",
            route: "notification/schedule",
            body: body
        });
        return result;
    }

    async UpdateSchedule(id: number, body: NotificationScheduleDto): Promise<NotificationScheduleDto> {
        let result = await DashboardAPI.callForData<NotificationScheduleDto>({
            method: "PATCH",
            route: `notification/schedule/${id}`,
            body: body
        });
        return result;
    }

    async DeleteSchedule(id: number): Promise<void> {
        await DashboardAPI.call({
            method: "DELETE",
            route: `notification/schedule/${id}`
        });
    }
}

const notificationService = new NotificationService()
export default notificationService;