import { useContext, useEffect, useState } from 'react';
import { Box, Button, ListItemIcon, ListItemText, Menu, MenuItem, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Typography } from '@mui/material';
import { visuallyHidden } from '@mui/utils';
import DeleteOutlineIcon from '@mui/icons-material/DeleteOutline';
import DescriptionOutlinedIcon from '@mui/icons-material/DescriptionOutlined';
import MoreHorizIcon from '@mui/icons-material/MoreHoriz';
import { CurrentFormContext } from './ToDoList';
import { formatDate } from '../utilities/HelperFunctions';
import { cardSX, inceptiaGreenAlphaColors } from '../utilities/CSS';
import { UserContext } from '../contexts/UserContext';
import { useSnackbar } from 'notistack';
import ConfirmationDialog from './ConfirmationDialog';
import TableRowsLoader, { type TableLoaderProps } from './TableRowsLoader';
import { iTodo } from '../utilities/APIInterfaces';
import { Priority, PriorityLabel, Status, StatusLabel, SubmissionTypeLabel, TaskTypeLabel } from '../utilities/FormEnums';
import userService from '../services/UserService';

const todoListTableSX = {
  tableHead: {
    fontSize: '14px',
    fontWeight: '500',
    p: 1.5,
    pt: 0,
    textAlign: 'center',
    textTransform: 'uppercase'
  },
  tableCell: {
    px: 1,
    textAlign: 'center'
  },
  fakeButton: {
    border: '1px solid #ccc',
    borderRadius: '100vw',
    fontSize: '0.875rem',
    padding: '3px 9px',
    whiteSpace: 'nowrap'
  },
  requiredCircle: {
    '&:before': {
      content: '""',
      backgroundColor: '#e53935', // Copied from Figma screen
      borderRadius: '100vw',
      display: 'inline-block',
      margin: '0 8px 2px 0',
      width: '6px',
      height: '6px'
    }
  }
};

const TableWrapper = ({ children }: { children: React.ReactNode }) => {
  return (
    <TableContainer>
      <Table aria-label="to-do list table">
        <TableHead>
          <TableRow>
            <TableCell component="th" sx={todoListTableSX.tableHead}>Name</TableCell>
            <TableCell component="th" sx={todoListTableSX.tableHead}>Priority</TableCell>
            <TableCell component="th" sx={todoListTableSX.tableHead}>Owner</TableCell>
            <TableCell component="th" sx={todoListTableSX.tableHead}>Institution</TableCell>
            <TableCell component="th" sx={todoListTableSX.tableHead}>Type</TableCell>
            <TableCell component="th" sx={todoListTableSX.tableHead}>Due Date</TableCell>
            <TableCell component="th" sx={todoListTableSX.tableHead}>Status</TableCell>
            <TableCell component="th" sx={todoListTableSX.tableHead}>
              {/* Fix for empty header cell accessibility error */}
              <Box sx={visuallyHidden}>Actions</Box>
            </TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {children}
        </TableBody>
      </Table>
    </TableContainer>
  );
};

const TableLoader = ({ colsNum, rowsNum, animation = 'pulse' }: TableLoaderProps) => {
  return (
    <TableWrapper>
      <TableRowsLoader colsNum={colsNum} rowsNum={rowsNum} animation={animation} />
    </TableWrapper>
  );
};

type ToDoListTableProps = {
  todoListView: 'active' | 'completed';
  isToDoListDataLoaded: boolean;
  onTodoListCountChange?: (newType: number) => void;
  rowData: iTodo[] | [];
  setIsToDoListDataLoaded: (isLoaded: boolean) => void;
};
export default function ToDoListTable({ todoListView, isToDoListDataLoaded, onTodoListCountChange, rowData, setIsToDoListDataLoaded }: ToDoListTableProps) {
  const { setOpenForm, setOpenFormIsOpen } = useContext(CurrentFormContext);
  const { enqueueSnackbar } = useSnackbar();

  useEffect(() => {
    onTodoListCountChange?.(rowData?.length || 0);
  }, [rowData?.length]);

  const userContext = useContext(UserContext);
  const currentUserAuthId = userContext.user?.authId?.toString();

  const [isRemoveDialogOpen, setIsRemoveDialogOpen] = useState(false);
  const [isRemoving, setIsRemoving] = useState(false);
  const [removeFormData, setRemoveFormData] = useState({} as { formID: number; formName: string; submissionId: number | null; });
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const isMoreMenuOpen = Boolean(anchorEl);
  const [isCannotOpenDialogOpen, setIsCannotOpenDialogOpen] = useState(false);
  const [cannotOpenFormData, setCannotOpenFormData] = useState({} as { formName: string; formStatus: string; retakeIssue: boolean; });

  useEffect(() => {
    const RemoveToDoListFormAsync = async () => {
      if (currentUserAuthId) {
        await userService.RemoveUserForm(removeFormData.formID, removeFormData.submissionId, currentUserAuthId, (v) => v)
          .then()
          .catch(error => {
            enqueueSnackbar(error.toString());
            console.log("RemoveToDoListFormAsync 1", error);
          }).finally(() => {
            setIsToDoListDataLoaded(false);
            setIsRemoving(false);
            setIsRemoveDialogOpen(false);
          });
      }
    };
  
    if (isRemoving) RemoveToDoListFormAsync();
  }, [isRemoving, currentUserAuthId, removeFormData, enqueueSnackbar, setIsRemoving, setIsToDoListDataLoaded, setIsRemoveDialogOpen]);

  const handleMoreButtonClick = (e: React.MouseEvent<HTMLButtonElement> | React.KeyboardEvent<HTMLButtonElement>) => {
    // Don't want to trigger the table row click event handler when clicking on the button
    e.stopPropagation();
    setAnchorEl(e.currentTarget);
  };

  const handleMoreMenuItemClick = (e: React.MouseEvent<HTMLLIElement>) => {
    // Don't want to trigger the table row click event handler when clicking on the button
    e.stopPropagation();
    const formID = Number(anchorEl!.getAttribute('data-row-id'));
    const formName = rowData.find(row => row.formId === formID)?.name || 'Did Not Find Form Name';
    const submissionId = rowData.find(row => row.formId === formID)?.formSubmissionId || null;
    setRemoveFormData({ formID, formName, submissionId });
    setIsRemoveDialogOpen(true);
    setAnchorEl(null);
  };

  const handleMoreMenuClose = (e: React.MouseEvent<HTMLButtonElement | HTMLLIElement>) => {
    // Don't want to trigger the table row click event handler when closing the more menu
    e.stopPropagation();
    setAnchorEl(null);
  };

  const handleRowClick = (formData: iTodo | null) => {
    let preventOpen = false, retakeIssue = false;

    // Cannot open form in approved, in-review, removed, or suspended status.
    // Cannot open form in pending-final-review status.
    // Cannot open form in parent-info-needed status if the form is owned by the user
    if (formData?.statusId && [
      Status.IN_REVIEW,
      Status.APPROVED,
      Status.REMOVED,
      Status.SUSPENDED,
      Status.PENDING_FINAL_REVIEW
    ].includes(formData.statusId) || 
    (formData?.statusId == Status.PARENT_INFO_NEEDED && formData.ownedBy == userContext.user?.id)
    ) preventOpen = true;




    // Cannot open form in declined or ineligible status IF the forms_version.CAN_RETAKE field is false.
    if (formData?.statusId && [Status.DECLINED, Status.INELIGIBLE].includes(formData.statusId) && ! formData.canRetake) {
      preventOpen = true;
      retakeIssue = true;
    }

    if (preventOpen) {
      setCannotOpenFormData({
        formName: formData?.name || 'Did Not Find Form Name',
        formStatus: StatusLabel.get(formData?.statusId) || 'Did Not Find Form Status',
        retakeIssue: retakeIssue
      });
      setIsCannotOpenDialogOpen(true);
    } else {
      setOpenForm(formData);
      setOpenFormIsOpen(true);
    }
  }

  return (
    <>
      {/* Loading... */}
      { ! isToDoListDataLoaded && <TableLoader colsNum={8} rowsNum={rowData.length || 3} />}

      {/* Loaded, but no forms in the to-do list */}
      {isToDoListDataLoaded && rowData.length === 0 && (
        <Box sx={cardSX.emptyStateBox}>
          <DescriptionOutlinedIcon sx={{ color: '#eee', fontSize: '7.125rem', mb: 2 }} />
          <Typography variant='body2'>{todoListView === 'active' ? 'Nothing for you to do yet.' : 'Nothing completed yet.'}</Typography>
        </Box>
      )}

      {/* Loaded, with forms in the to-do list */}
      {isToDoListDataLoaded && rowData.length > 0 && (
        <TableWrapper>
          {rowData.map(row => (
            <TableRow
              hover
              role='button' // Keyboard accessibility
              key={'' + row.name + row.orgId + row.formId + row.formSubmissionId}
              sx={{
                cursor: 'pointer',
                '&:last-child td, &:last-child th': { border: 0 },
                '&:focus-visible': { backgroundColor: inceptiaGreenAlphaColors.hover }
              }}
              tabIndex={0} // Keyboard accessibility
              onClick={() => handleRowClick(row)}
              onKeyDown={(e: React.KeyboardEvent<HTMLTableRowElement>) => {
                // Keyboard accessibility - open to-do item when pressing enter or space
                if (e.key === 'Enter' || e.key === ' ') handleRowClick(row);
              }}
            >
              <TableCell sx={{
                color: 'InceptiaGreen.main',
                fontSize: '1rem',
                fontWeight: '500',
                px: 1
              }}>{row.name}</TableCell>

              <TableCell sx={todoListTableSX.tableCell}>
                <Box component='span' sx={{
                  ...todoListTableSX.fakeButton,
                  ...(row.priorityId === Priority.REQUIRED && todoListTableSX.requiredCircle)
                }}>
                  {PriorityLabel.get(row.priorityId) || ""}
                </Box>
              </TableCell>
              {/* <TableCell sx={todoListTableSX.tableCell}>{row.ownedBy}</TableCell> */}
              <TableCell sx={todoListTableSX.tableCell}>{SubmissionTypeLabel.get(row.submissionTypeId)}</TableCell>
              <TableCell sx={todoListTableSX.tableCell}>{row.organization}</TableCell>
              <TableCell sx={todoListTableSX.tableCell}>{TaskTypeLabel.get(row.taskTypeId)}</TableCell>
              <TableCell sx={todoListTableSX.tableCell}>{formatDate(row.dueDate)}</TableCell>

              <TableCell sx={todoListTableSX.tableCell}>
                <Box component='span' sx={todoListTableSX.fakeButton}>{StatusLabel.get(row.statusId)}</Box>
              </TableCell>

              <TableCell sx={{...todoListTableSX.tableCell, pr: 0 }}>
                <Button
                  id={`more-button-${row.formId}`}
                  aria-controls={isMoreMenuOpen ? 'more-menu' : undefined}
                  aria-expanded={isMoreMenuOpen ? 'true' : undefined}
                  aria-haspopup='true'
                  aria-label='More form options'
                  color='inherit'
                  data-row-id={row.formId}
                  disabled={todoListView === 'completed'}
                  onClick={handleMoreButtonClick}
                  onKeyDown={(e: React.KeyboardEvent<HTMLButtonElement>) => {
                    // Keyboard accessibility - click More button when pressing enter or space
                    if (e.key === 'Enter' || e.key === ' ') handleMoreButtonClick(e);
                  }}
                  sx={{ minWidth: 'revert' }}
                >
                  <MoreHorizIcon />
                </Button>
              </TableCell>
            </TableRow>
          ))}
        </TableWrapper>
      )}

      {/* Don't want the menu to be duplicated for each row in the table */}
      <Menu
        id='more-menu'
        anchorEl={anchorEl}
        open={isMoreMenuOpen}
        onClose={handleMoreMenuClose}
        MenuListProps={{
          'aria-labelledby': 'more-button',
          dense: true,
        }}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'right'
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'right'
        }}
      >
        <MenuItem onClick={handleMoreMenuItemClick}>
          <ListItemIcon sx={{ color: 'inherit', '&.MuiListItemIcon-root': { minWidth: '32px' } }}>
            <DeleteOutlineIcon />
          </ListItemIcon>
          <ListItemText primaryTypographyProps={{ fontWeight: '500' }}>Remove Form</ListItemText>
        </MenuItem>
      </Menu>

      {isRemoveDialogOpen && <ConfirmationDialog
        ariaPrefix='remove-form'
        dialogTitle='Are you sure?'
        singleButton={false}
        ctaButtonText='Remove'
        ctaButtonWorkingText='Removing...'
        cancelButtonText='Cancel'
        open={isRemoveDialogOpen}
        setOpenDialog={setIsRemoveDialogOpen}
        isWorking={isRemoving}
        setIsWorking={setIsRemoving}
      >
        <Typography variant='body2'>This will remove <b>{removeFormData.formName}</b> from your To-Do List.</Typography>
      </ConfirmationDialog>}

      {/* Form CANNOT be opened - tell user why */}
      {isCannotOpenDialogOpen && <ConfirmationDialog
        ariaPrefix='cannot-open-form'
        dialogTitle='Unable to Open Form'
        singleButton={true}
        ctaButtonText='Close'
        ctaButtonWorkingText='Closing...'
        cancelButtonText=''
        open={isCannotOpenDialogOpen}
        setOpenDialog={setIsCannotOpenDialogOpen}
      >
        {/* Cannot open form in approved, in-review, removed, or suspended status. */}
        { ! cannotOpenFormData.retakeIssue && (
          <Typography variant='body2' sx={{ mb: 2 }}>
            The <b>{cannotOpenFormData.formName}</b> form can't be opened because of its <b>{cannotOpenFormData.formStatus}</b> status.
          </Typography>
        )}
        {/* Cannot open form in declined or ineligible status IF the forms_version.CAN_RETAKE field is false. */}
        {cannotOpenFormData.retakeIssue && (
          <Typography variant='body2' sx={{ mb: 2 }}>
            The <b>{cannotOpenFormData.formName}</b> form can't be opened because of its <b>{cannotOpenFormData.formStatus}</b> status and it's not eligible to be retaken.
          </Typography>
        )}
       </ConfirmationDialog>}
    </>
  );
}