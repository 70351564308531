import { ClearFilesEvent, UploadFilesEvent } from 'survey-core';
import documentService from '../services/DocumentService';
import { iDocumentDTO } from './APIInterfaces';
import { renameFile } from './HelperFunctions';

export const handleUploadFiles = async (authId: string, uploaderAuthId: string, options: UploadFilesEvent, submissionDocuments: iDocumentDTO[],
  enqueueSnackbar: (message: string, options: any) => void): Promise<iDocumentDTO[]> => {

  const formData = new FormData();
  formData.append("uploaderAuthId", uploaderAuthId);
  let hasValidFiles: boolean = true;
  for await (const file of options.files){
    await file.text().then(async (x) => {
      if (x.includes("Encrypt") || x.substring(x.lastIndexOf("<<"), x.lastIndexOf(">>")).includes("/Encrypt")) {
        hasValidFiles = false;
        enqueueSnackbar(`Cannot upload password protected or encrypted file: ${file.name}`, { variant: 'error' });
        options.callback("error");
      } else {
        formData.append("formFileCollection", file);
      }
    });
  };
  
  if (hasValidFiles){
    return await surveyUploadFile(authId, formData, options, submissionDocuments, enqueueSnackbar);
  } else {
    return submissionDocuments;
  }
}

const surveyUploadFile = async(authId: string, formData: FormData, options: UploadFilesEvent, submissionDocuments: iDocumentDTO[],
  enqueueSnackbar: (message: string, options: any) => void): Promise<iDocumentDTO[]> => {
  if (formData.has("formFileCollection")) {
    await documentService.UploadFormFiles(formData, authId, (v) => v)
    // the response is json if successful and text if not.
    // convert the response to text, then try to convert to json.
    .then((response) => response.text())
    .then((body) => {
      try {
        let data : iDocumentDTO[] = JSON.parse(body);
        submissionDocuments.push(...data);
        options.callback(
          "success",
          options.files.map((file) => {
          let documentObj = data.filter((item: iDocumentDTO) => {return item.original_File_Name === file.name;});
            return {
              file: renameFile(file, documentObj[0].file_Name),
              content: process.env.REACT_APP_DASHBOARD_API_URL + "/Document/" + documentObj[0].uuid
            };
          })
        );
      } catch (error) {
        throw Error((error as Error).message);
      }
    })
    .catch((error) => {
      enqueueSnackbar(error.toString(), { variant: 'error' });
      console.error("Error: ", error);
      options.callback("error");
    });
  }
  return submissionDocuments;
}

export const handleClearFiles = async (authId: string, options: ClearFilesEvent, submissionDocuments: iDocumentDTO[],
  enqueueSnackbar: (message: string, options: any) => void): Promise<iDocumentDTO[]> => {

  if (options.question.getType() === "file") {
    if (!options.value || options.value.length === 0) return options.callback("success");

    if (!options.fileName && !!options.value) {
      // "Clear" eraser icon button clicked - delete all files
      for (const item of options.value) {
        await deleteFile(authId, item.content, options, submissionDocuments, enqueueSnackbar);
      }

      // Tell SurveyJS that the operation is complete now that we've looped through all the files
      options.callback("success");
    } else {
      // Trash can icon button clicked - delete single file
      const fileToRemove = options.value.find(
        (item: { name: string; }) => item.name === options.fileName
      );
      if (fileToRemove) {
        await deleteFile(authId, fileToRemove.content, options, submissionDocuments, enqueueSnackbar);

        // Tell SurveyJS that the operation is complete now that we've deleted the single file
        options.callback("success");
      } else {
        console.error(`File with name ${options.fileName} was not found`);
      }
    }
  }
  
  return submissionDocuments;
};

const deleteFile = async (authId: string, fileURL: string, options: ClearFilesEvent, submissionDocuments: iDocumentDTO[],
  enqueueSnackbar: (message: string, options: any) => void) => {

  await documentService.DeleteFormFile(authId, fileURL, (v) => v)
    .then((response) => {
      if (response.status === 200) {
        if (submissionDocuments.length) {
          const fileUuid = fileURL.substring(fileURL.lastIndexOf('/') + 1);
          // submissionDocuments = submissionDocuments.filter((item: iDocumentDTO) => { return item.uuid !== fileUuid; });

          // Handle the removal from the array this way so that calling code will get the "updated" array (.filter creates a new array)
          for (let i = 0; i < submissionDocuments.length; i++) {
            if (submissionDocuments[i].uuid === fileUuid) {
              submissionDocuments.splice(i, 1);
              break;
            }
          }
        }
        enqueueSnackbar('File deleted successfully', { variant: 'success' });
        // Inceptia WANTS the document to remain in the JSON even after delete
        // Goal is to prevent deletes in the future

        /*
          FEBS-1006 - Remove the code that keeps deleted documents in the submitted json
          
          If more than a single file is being deleted (e.g. user clicked the "Clear all files" eraser icon button),
          calling the callback here will result in only the first file being deleted - believe the callback tells SurveyJS
          that the operation is complete.
          
          The submission's JSON will have all files removed from it, and additional calls to deleteFile() will not be made.

          The submission_forms_document and document tables will still have records for files beyond the first file deleted.
          And the files beyond the first will still be in the folder on the server.

          Moving the options.callback("success") call to the calling code.
        */
        // options.callback("success");
      } else {
        options.callback("error");
        throw Error(`Failed to delete file: ${fileURL}`);
      }
    }).catch((error) => {
      enqueueSnackbar(error.toString(), { variant: 'error' });
      console.error("Error: ", error);
      options.callback("error");
    });
};
