import {
    Dialog,
    DialogTitle,
    DialogActions,
    Button,
    IconButton,
    useMediaQuery,
    useTheme,
    DialogContent,
    MenuItem,
    Grid,
    FormControl,
    InputLabel,
    Typography,
    Select,
    TextField,
    CircularProgress
} from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import Radio from '@mui/material/Radio';
import RadioGroup from '@mui/material/RadioGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import { useState, useContext, useEffect } from 'react';
import { inceptiaGreenAlphaColors } from '../utilities/CSS';
import { useSnackbar } from 'notistack';
import userService from '../services/UserService';
import { iKeyCloakUserRequestDTO, iProductDTO } from '../utilities/APIInterfaces';
import { Formik, Form, Field, ErrorMessage, FieldProps } from 'formik';
import * as Yup from 'yup';
import { OrganizationContext, iOrganization } from '../contexts/OrganizationContext';
import orgService from '../services/OrgService';



type AdminAddUserModalProps = {
    addUserIsOpen: boolean;
    setAddUserIsOpen: (addUserIsOpen: boolean) => void;
};

const AdminAddUserModal = ({ addUserIsOpen, setAddUserIsOpen }: AdminAddUserModalProps) => {
    const theme = useTheme();
    const fullScreen = useMediaQuery(theme.breakpoints.down('sm'));
    const { enqueueSnackbar } = useSnackbar();
    const [isSaving, setIsSaving] = useState(false);
    const [accountType, setAccountType] = useState('Institution');
    const [userRoleOptions, setUserRoleOptions] = useState<{ value: string; label: string }[]>([]);
    const [productOptions, setProductOptions] = useState<iProductDTO[]>([]);
    const { organization } = useContext(OrganizationContext);
    const organizationDisplay = `${organization?.opeid} - ${organization?.name}` || '';

    // Close the modal
    const handleClose = (event: React.MouseEvent) => {
        event.stopPropagation();
        setAccountType('');
        setUserRoleOptions([]);
        setAddUserIsOpen(false);
    };

    useEffect(() => {
        // set accountType to Institution by default
        setAccountType('Institution');
        // execute the handleAccountTypeChange function to set the userRoleOptions
        handleAccountTypeChange({ target: { value: 'Institution' } } as React.ChangeEvent<HTMLInputElement>);

    }, []);

    // Handle the accountType radio
    const handleAccountTypeChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        setAccountType(event.target.value);

        // determine the MenuItem values for the userRole based on the accountType
        if (event.target.value === 'Institution') {
            setUserRoleOptions([
                { value: 'InstitutionAdmin', label: 'Institution Admin' },
                { value: 'Institution', label: 'Institution User' },
            ]);
        } else if (event.target.value === 'Student') {
            // FUTURE: the entire form for adding a student user will need to be different
        }
    };

    // populate the productOrgLinkList
    useEffect(() => {
        if (organization) {
            orgService
                .GetProductOrgLinkList(organization.id, (v) => v)
                .then((response) => {
                    setProductOptions(response as iProductDTO[]);
                })
                .catch((error) => {
                    enqueueSnackbar(error.toString());
                    console.log('AdminAddUserModal 1', error);
                });
        }
    }, [organization, enqueueSnackbar]);

    const createKeyCloakUserAsync = async (values: any) => {
        const { firstName, lastName, email, userRole, userAccess } = values; // Not currently using accountType, or institution
        setIsSaving(true);

        // if userAccess includes 'All' (value = 0), then overwrite userAccess to all product ids in productOptions
        let productIdList: number[] = [];
        if (userAccess.includes(0)) {
            const allProductIds = productOptions.map((product) => product.id);
            productIdList = productIdList.concat(allProductIds);
        } else {
            productIdList = userAccess;
        }

        const createKeyCloakUser: iKeyCloakUserRequestDTO = {
            id: '',
            firstName: firstName,
            lastName: lastName,
            email: email,
            orgId: organization?.id.toString() || '',
            groups: [userRole],
            productIdList: productIdList, 
        };

        userService
            .CreateKeyCloakUser(createKeyCloakUser, (v) => v)
            .then()
            .catch((error) => {
                enqueueSnackbar(error.toString());
                console.log('createKeyCloakUserAsync 1', error);
            })
            .finally(() => {
                setAddUserIsOpen(false);
                setIsSaving(false);
            });
    };

    return (
        <Dialog
            open={addUserIsOpen}
            onClose={handleClose}
            maxWidth='sm'
            fullScreen={fullScreen}
            fullWidth
            PaperProps={{
                sx: { borderRadius: { xs: 0, sm: 2 } },
            }}
            aria-labelledby='add-user-modal-title'
            aria-describedby='add-user-modal-content'
        >
            <DialogTitle
                id='add-user-modal-title'
                sx={{
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'space-between',
                    fontSize: 32,
                    fontWeight: 700,
                }}
            >
                Add User
                <IconButton edge='end' color='inherit' onClick={handleClose} aria-label='close'>
                    <CloseIcon fontSize='large' />
                </IconButton>
            </DialogTitle>

            <DialogContent id='add-user-modal-content'>
                <Formik
                    initialValues={{
                        accountType: 'Institution',
                        institution: organizationDisplay, 
                        firstName: '',
                        lastName: '',
                        email: '',
                        userRole: '',
                        userAccess: [],
                    }}
                    validationSchema={Yup.object({
                        accountType: Yup.string().required('Required'),
                        institution: Yup.string().required('Required'),
                        firstName: Yup.string()
                            .max(15, 'Must be 15 characters or less')
                            .required('Required')
                            .test('not-only-spaces', 'First name cannot be only spaces', value => value?.trim().length > 0),
                        lastName: Yup.string()
                            .max(20, 'Must be 20 characters or less')
                            .required('Required')
                            .test('not-only-spaces', 'Last name cannot be only spaces', value => value?.trim().length > 0),
                        email: Yup.string()
                            .email('Invalid email address')
                            .required('Required')
                            .test('not-only-spaces', 'Email cannot be only spaces', value => value?.trim().length > 0),
                        userRole: Yup.string().required('Required'),
                        userAccess: Yup.array().min(1, 'At least one access type is required').required('User access is required'),
                    })}
                    onSubmit={async (values, { setSubmitting }) => {
                        await createKeyCloakUserAsync(values);
                    }}
                >
                    {formik => (
                        <Form>
                            <Grid item xs={12} sx={{ mb: 2 }}>
                                <Typography sx={{ fontWeight: 700, pb: 1 }}>Account Type</Typography>
                                <RadioGroup
                                    aria-label="accountType"
                                    name="accountType"
                                    value={accountType}
                                    onChange={handleAccountTypeChange}
                                    onBlur={formik.handleBlur}
                                    row
                                >
                                    <FormControlLabel value="Institution" control={<Radio />} label="Institution" />
                                    <FormControlLabel value="Student" control={<Radio disabled />} label="Student" />
                                </RadioGroup>
                            </Grid>

                            <Grid item xs={12} sx={{ mb: 2 }}>
                                {/* FUTURE: this may need to be a dropdown to be able to select a single child org under a Corp org */}
                                <Field
                                    fullWidth
                                    as={TextField}
                                    id='institution'
                                    name='institution'
                                    label='Institution'
                                    onChange={formik.handleChange}
                                    onBlur={formik.handleBlur}
                                    error={formik.touched.institution && Boolean(formik.errors.institution)}
                                    helperText={formik.touched.institution && formik.errors.institution}
                                    disabled
                                />
                            </Grid>

                            <Grid item xs={12} sx={{ mb: 2 }}>
                                <Field
                                    fullWidth
                                    as={TextField}
                                    id='firstName'
                                    name='firstName'
                                    label='First Name'
                                    value={formik.values.firstName}
                                    onChange={formik.handleChange}
                                    onBlur={formik.handleBlur}
                                    error={formik.touched.firstName && Boolean(formik.errors.firstName)}
                                    helperText={formik.touched.firstName && formik.errors.firstName}
                                />
                            </Grid>

                            <Grid item xs={12} sx={{ mb: 2 }}>
                                <Field
                                    fullWidth
                                    as={TextField}
                                    id='lastName'
                                    name='lastName'
                                    label='Last Name'
                                    value={formik.values.lastName}
                                    onChange={formik.handleChange}
                                    onBlur={formik.handleBlur}
                                    error={formik.touched.lastName && Boolean(formik.errors.lastName)}
                                    helperText={formik.touched.lastName && formik.errors.lastName}
                                />
                            </Grid>

                            <Grid item xs={12} sx={{ mb: 2 }}>
                                <Field
                                    fullWidth
                                    as={TextField}
                                    id='email'
                                    name='email'
                                    label='Email'
                                    value={formik.values.email}
                                    onChange={formik.handleChange}
                                    onBlur={formik.handleBlur}
                                    error={formik.touched.email && Boolean(formik.errors.email)}
                                    helperText={formik.touched.email && formik.errors.email}
                                />
                            </Grid>

                            <Grid item xs={12} sx={{ mb: 2 }}>
                                <FormControl fullWidth>
                                    <InputLabel id="user-role-label">User Role</InputLabel>
                                    <Field name="userRole">
                                        {({ field }: FieldProps) => (
                                            <Select
                                                {...field}
                                                fullWidth
                                                id='userRole'
                                                name='userRole'
                                                label='User Role'
                                                value={formik.values.userRole}
                                                onChange={formik.handleChange}
                                                onBlur={formik.handleBlur}
                                                error={formik.touched.userRole && Boolean(formik.errors.userRole)}
                                            >
                                                {userRoleOptions.map((option) => (
                                                    <MenuItem key={option.value} value={option.value}>
                                                        {option.label}
                                                    </MenuItem>
                                                ))}
                                            </Select>
                                        )}
                                    </Field>
                                </FormControl>
                            </Grid>

                            <Grid item xs={12} sx={{ mb: 2 }}>
                                <FormControl fullWidth>
                                    <InputLabel id="user-access-label">User Access</InputLabel>
                                    <Field name="userAccess">
                                        {({ field }: FieldProps) => (
                                            <Select
                                                {...field}
                                                fullWidth
                                                id='userAccess'
                                                name='userAccess'
                                                label='User Access'
                                                multiple
                                                value={formik.values.userAccess}
                                                onChange={(event) => {
                                                    const value = event.target.value as string[];
                                                    if (value.includes('All') && value.length > 1) {
                                                        formik.handleChange({ target: { name: 'userAccess', value: ['All'] } });
                                                    } else if (value.includes('All')) {
                                                        formik.handleChange({ target: { name: 'userAccess', value: ['All'] } });
                                                    } else {
                                                        formik.handleChange({ target: { name: 'userAccess', value } });
                                                    }
                                                }}
                                                onBlur={formik.handleBlur}
                                                error={formik.touched.userAccess && Boolean(formik.errors.userAccess)}
                                            >
                                                <MenuItem value={0}>All</MenuItem>
                                                {productOptions.map((option) => (
                                                    <MenuItem key={option.id} value={option.id}>
                                                        {option.name}
                                                    </MenuItem>
                                                ))}
                                            </Select>
                                        )}
                                    </Field>
                                    <ErrorMessage name="userAccess" component="div" />
                                </FormControl>
                            </Grid>

                            <DialogActions
                                sx={{
                                    color: 'InceptiaGreen.main',
                                    borderTop: '2px solid',
                                    borderColor: 'grey.100',
                                    p: 3,
                                    justifyContent: 'center',
                                }}
                            >
                                <Grid container rowSpacing={{ xs: 1.5, sm: 2 }} columnSpacing={2}>
                                    <Grid item xs={12} md={6}>
                                        <Button
                                            variant='outlined'
                                            size='large'
                                            color='inherit'
                                            sx={{
                                                textTransform: 'none',
                                                '&:hover, &:focus': {
                                                    backgroundColor: inceptiaGreenAlphaColors.hover,
                                                },
                                                height: '48px', // Increase the height of the button
                                                width: '100%', // Take up the entire space
                                            }}
                                            onClick={handleClose}
                                        >
                                            Cancel
                                        </Button>
                                    </Grid>
                                    <Grid item xs={12} md={6}>
                                        <Button
                                            variant='contained'
                                            size='large'
                                            sx={{
                                                textTransform: 'none',
                                                bgcolor: 'InceptiaGreen.main',
                                                '&:hover': {
                                                    backgroundColor: 'InceptiaGreen.dark',
                                                },
                                                height: '48px', // Increase the height of the button
                                                width: '100%', // Take up the entire space
                                            }}
                                            type='submit'
                                            disabled={formik.isSubmitting}
                                        >
                                            {formik.isSubmitting ? (
                                                <>
                                                    <CircularProgress color='inherit' size={20} sx={{ mr: 1.5 }} />
                                                    Sending...
                                                </>
                                            ) : (
                                                'Submit'
                                            )}
                                        </Button>
                                    </Grid>
                                </Grid>
                            </DialogActions>
                        </Form>
                    )}
                </Formik>
            </DialogContent>
        </Dialog >
    );
};

export default AdminAddUserModal;
