import DashboardAPI from '../apis/DashboardAPI';
import KeycloakService from '../services/KeycloakService';

interface ApiOptions {
    route: string,
    method?: string | undefined,
    body?: {} | undefined,
}

export interface User {
    userId: string;
    authId: string;
    firstname: string;
    lastName: string;
    email: string;
    productId: string[];
    role: string;
}

class AdminUsersService {

    // Method to make API calls, modified to handle file uploads
    // PRIVATE
    async call(options: ApiOptions): Promise<Response> {
        let headers;

        // Check if user is logged in using KeycloakService
        if (KeycloakService.isLoggedIn()) {
            headers = {
                Authorization: `Bearer ${ KeycloakService.getToken() }`
            }
        } else {
            headers = {};
        }

        // Ensure the body is a FormData object when calling this method with a file
        if (!(options.body instanceof FormData)) {
            throw new Error("Body must be a FormData object when uploading files.");
        }

        // Make the API call using fetch
        return await fetch(`${process.env.REACT_APP_DASHBOARD_API_URL}/${options.route}`, {
            method: options.method,
            headers: headers,
            body: options.body
        })
            .catch((error) => {
                console.log(`${AdminUsersService.name}: call with file upload error: ${error}`);
                throw error;
            });
    }

    // Method to upload a file
    async UploadFile(file: FormData) {
        let response = await this.call({
            method: "POST",
            route: "api/FileUpload/UploadFile",
            body: file
        });
        return response;
    }

    async GetInstitutionUsers(orgId: string | undefined, loader: (v: boolean) => void) {
        let result = null;
    
        if (orgId != null) {
            loader(true);
            result = await DashboardAPI.callForData({
                method: "GET",
                route: `api/InstitutionUsers/institutionUsers/${orgId}`
            });
            loader(false);
        }
        return result;
    }
    
    async DeleteAdminUser(userUuid: string, authId: string | null, loader: (v: boolean) => void) {
        let response = null;
    
        if (authId !== null) {
            loader(true);
            response = await DashboardAPI.callForData({
                route: `api/InstitutionUsers/deleteUser/${authId}`,
                method: 'DELETE'
            });
            loader(false);
        }
    
        return response;
    }

}


// Create an instance of AdminUsersService
const adminUsersService = new AdminUsersService()

export default adminUsersService;