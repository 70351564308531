import { Autocomplete, Box, Button, createFilterOptions, ListItemText, Menu, MenuItem, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, TextField, Typography } from '@mui/material';
import { AdminFormCrudActionType, AdminFormCrudProps } from '../../pages/AdminFormCrud';
import { visuallyHidden } from '@mui/utils';
import MoreHorizIcon from '@mui/icons-material/MoreHoriz';
import { inceptiaGreenAlphaColors } from '../../utilities/CSS'
import { useContext, useEffect, useRef, useState } from 'react';
import { RoleRouteMapper } from '../RoleRouteMapper';
import { AdminRoleNodes, iAdminTag, iTagDTO } from '../../utilities/APIInterfaces';
import tagService from "../../services/TagService";
import { AdminFormDataContext } from '../../contexts/AdminFormDataContext';
import { enqueueSnackbar } from 'notistack';
import ConfirmationDialog from '../ConfirmationDialog';
import { OrganizationContext } from '../../contexts/OrganizationContext';

const comTableSx = {
  tableHead: {
    fontSize: '14px',
    fontWeight: '500',
    p: 1.5,
    pt: 0,
    textAlign: 'left',
    textTransform: 'uppercase'
  },
  tableCell: {
    textAlign: 'right'
  }
};

const FormCrudTags = ({ editAccess = false, ...props }: AdminFormCrudProps) => {
  const {organization, isOrganizationDataLoaded, setIsOrganizationDataLoaded } = useContext(OrganizationContext);
  const {formData, isAdminFormDataLoaded, setIsAdminFormDataLoaded } = useContext(AdminFormDataContext);
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const [data, setData] = useState<iAdminTag[]>();
  const [isEditDialogOpen, setIsEditDialogOpen] = useState(false);
  const [isEditing, setIsEditing] = useState(false);
  const [isDeleteDialogOpen, setIsDeleteDialogOpen] = useState(false);
  const [isDeleting, setIsDeleting] = useState(false);
  const [isAddDialogOpen, setIsAddDialogOpen] = useState(false);
  const [isAdding, setIsAdding] = useState(false);
  const [selectedTag, setSelectedTag] = useState<iAdminTag>();
  const [orgTags, setOrgTags] = useState<iTagDTO[]>([]);
  const [selectedTags, setSelectedTags] = useState<iTagDTO[]>([]);
  const open = Boolean(anchorEl);
  const textRef = useRef();

  function handleRowClick(row: iAdminTag): void {
    console.log(row);
  }

  const handleButtonClick = (event: React.MouseEvent<HTMLButtonElement> | React.KeyboardEvent<HTMLButtonElement>) => {
    // Don't want to trigger the table row click event handler when clicking on the button
    event.stopPropagation();
    setAnchorEl(event.currentTarget);
  };

  const handleMenuClick = (event: React.MouseEvent<HTMLLIElement>) => {
    // Don't want to trigger the table row click event handler when clicking on the button
    event.stopPropagation();

    // Get the tag's ID and then tag
    const tagId = Number(anchorEl!.getAttribute('data-row-id'));
    const tag = data?.find(tag => tag.id === tagId);

    // Need to know which menu item was clicked (edit or delete)
    const action = (event.target as HTMLElement).closest('li')?.getAttribute('data-menu-action')?.toLowerCase();
    setSelectedTag(tag);

    switch (action) {
      case 'edit':
        setIsEditDialogOpen(true);
        break;
      case 'delete':
        setIsDeleteDialogOpen(true);
        break;
      case 'remove':
        if (isAdminFormDataLoaded && formData && tag){
          tagService.RemoveFormTag(tag.id, formData.uuid, (v) => v)
          .finally(() => {
            setIsAdminFormDataLoaded(false);
          });
        }
        break;
    }
    setAnchorEl(null);
  };

  const handleMenuClose = (event: React.MouseEvent<HTMLLIElement, MouseEvent>) => {
    event.stopPropagation();
    setAnchorEl(null);
  };

  const handleSaveEditTagClick = () => {
    const oldTag = data?.find(tag => tag.id === selectedTag?.id);
    const oldTagDTO: iTagDTO = tagService.MapToDto(oldTag, organization!.id);
    const editTagDTO: iTagDTO = tagService.MapToDto(selectedTag, organization!.id);
    tagService.UpdateTag(oldTagDTO, editTagDTO, (v) => v)
      .finally(() => {
        setIsAdminFormDataLoaded(false);
        setIsEditing(false);
        setIsEditDialogOpen(false);
      });
  }

  const handleDeleteTagClick = () => {
    tagService.DeleteTag(selectedTag!.id, (v) => v)
      .finally(() => {
        setIsAdminFormDataLoaded(false);
        setIsDeleting(false);
        setIsDeleteDialogOpen(false);
      });
  }

  const handleAddTagClick = () => {
    if (props.action === 'add') {
      if (props && props.setTags) props.setTags(selectedTags);
      setIsAdminFormDataLoaded(false);
      setIsAdding(false);
      setIsAddDialogOpen(false);
    } else {
      if (isAdminFormDataLoaded && formData){
        tagService.AddTags(formData.uuid, selectedTags, (v) => v)
        .finally(() => {
          setIsAdminFormDataLoaded(false);
          setIsAdding(false);
          setIsAddDialogOpen(false);
        });
      }
    }
  }

  const _filterOptions = createFilterOptions();
  const filterOptions = (options: any, state: any) => {
    const results = _filterOptions(options, state);
    const defaultTagOption: iTagDTO = { id: -1, tag_Name: "+ Create New", org_Id: organization!.id };
      if (!results.includes(defaultTagOption)) {
        results.unshift(defaultTagOption);
      }
      return results;
  };

  function getFormAndApplicantCountMessage(formsCount: number, submissionsCount: number) {
    let message: string = `${formsCount} form`;
    if (formsCount > 1)
      message += "s";

    if (submissionsCount > 0)
      message += ` and ${submissionsCount} applicant`

    if (submissionsCount > 1)
      message += "s";

    message += ".";
    return message;
  }

  useEffect(() => {
    if(props.action === "add" && props.tags && props.tags?.length > 0){
      let adminTags: iAdminTag[] = [];
      props.tags.forEach(tag => {
        adminTags.push({
          id: tag.id,
          tag_Name: tag.tag_Name,
          forms_Using: 0,
          submissions_Using: 0
        });
      });
      setData(adminTags);
    } else {
      if (isAdminFormDataLoaded && formData){
        tagService.GetAdminFormTags(formData.uuid, (v) => v)
        .then((result: iAdminTag[]) => {
          if (result) {
            setData(result);
          }
        })
        .catch((error) => {
          enqueueSnackbar(error.toString());
        });
      }
    }
  }, [isAdminFormDataLoaded, enqueueSnackbar]);

  useEffect(() => {
    if (isOrganizationDataLoaded && organization){
      tagService.GetOrgTags(organization.opeid, (v) => v)
      .then((result: iTagDTO[]) => {
        if (result) {
          setOrgTags(result);
        }
      })
      .catch((error) => {
        enqueueSnackbar(error.toString());
      })
    }
  }, [isOrganizationDataLoaded, enqueueSnackbar]);

  return (<>
    <RoleRouteMapper 
        roles={Object.keys(new AdminRoleNodes())
          .filter((role) => ['admin','manager'].includes(role))}
      >
        <Button 
          variant="contained"
          sx={{
            fontSize: '1rem',
            float:'right',
            textTransform: 'none',
            bgcolor: 'InceptiaGreen.main',
            '&:hover': {
              backgroundColor: 'InceptiaGreen.dark',
            },
          }}
          onClick={() => setIsAddDialogOpen(true)}
        >+ Add Tags</Button>
      </RoleRouteMapper>
    <Typography variant='h2' sx={{ fontSize: '1.5rem', fontWeight: 500, mb: 4.5 }}>Tags</Typography>
    <TableTemplate action={props.action} children={<>
      {data?.map((row, index) => {
        return <TableRow
          hover
          role='button' // Keyboard accessibility
          key={index}
          sx={{
            cursor: 'pointer',
            '&:focus-visible': { backgroundColor: inceptiaGreenAlphaColors.hover }
          }}
          tabIndex={0} // Keyboard accessibility
          onClick={() => handleRowClick(row)}
          onKeyDown={(e: React.KeyboardEvent<HTMLTableRowElement>) => {
            // Keyboard accessibility - open form when pressing enter or space
            if (e.key === 'Enter' || e.key === ' ') handleRowClick(row);
          }}
        >
          <TableCell sx={{
            color: 'InceptiaGreen.main',
            fontSize: '1rem',
            fontWeight: '500',
            px: 1
          }}>{row.tag_Name}</TableCell>

          <TableCell>{row.forms_Using}</TableCell>

          <TableCell sx={{...comTableSx.tableCell, pr: 0 }}>
          <Button
            id={`more-button-${row.id}`}
            aria-controls={open ? 'basic-menu' : undefined}
            aria-haspopup="true"
            aria-expanded={open ? 'true' : undefined}
            color='inherit'
            data-row-id={row.id}
            onClick={handleButtonClick}
            onKeyDown={(e: React.KeyboardEvent<HTMLButtonElement>) => {
              // Keyboard accessibility - open form when pressing enter or space
              if (e.key === 'Enter' || e.key === ' ') handleButtonClick(e);
            }}
            sx={{ minWidth: 'revert', ml: 'auto' }}
          >
            <MoreHorizIcon />
          </Button>
          <Menu
            id="basic-menu"
            anchorEl={anchorEl}
            open={open}
            onClose={handleMenuClose}
            MenuListProps={{
                'aria-labelledby': 'more-button',
                dense: true,
                sx: {
                  minWidth: '180px',
                  textAlign: 'right'
                }
            }}
            anchorOrigin={{
                vertical: 'bottom',
                horizontal: 'right'
            }}
              transformOrigin={{
                vertical: 'top',
                horizontal: 'right'
            }}
          >
            <MenuItem data-menu-action='edit' disabled={!editAccess} onClick={handleMenuClick}>
              <ListItemText primaryTypographyProps={{ fontWeight: '500' }}>Edit Tag</ListItemText>
            </MenuItem>
            <MenuItem data-menu-action='remove' disabled={!editAccess} onClick={handleMenuClick}>
              <ListItemText primaryTypographyProps={{ fontWeight: '500' }}>Remove Tag</ListItemText>
            </MenuItem>
            <MenuItem data-menu-action='delete' disabled={!editAccess} onClick={handleMenuClick}>
              <ListItemText primaryTypographyProps={{ fontWeight: '500' }}>Delete Tag</ListItemText>
            </MenuItem>
          </Menu>
          </TableCell>
        </TableRow>
    })}</>}/>

    {isEditDialogOpen && <ConfirmationDialog
      ariaPrefix='edit-tag'
      dialogTitle='Edit Tag'
      singleButton={false}
      ctaButtonText='Save'
      ctaButtonWorkingText='Saving...'
      cancelButtonText='Cancel'
      open={isEditDialogOpen}
      setOpenDialog={setIsEditDialogOpen}
      isWorking={(isEditing)}
      setIsWorking={() => {
        handleSaveEditTagClick();
      }}
    >
      <Typography variant='body2' sx={{ mb: 2 }}>This will edit this tag for <b>{getFormAndApplicantCountMessage(selectedTag!.forms_Using, selectedTag!.submissions_Using)}</b></Typography>
      <TextField
        id='editTagDialog'
        variant='outlined'
        size='small'
        autoFocus
        fullWidth
        minRows={1}
        maxRows={1}
        placeholder='Tag Name'
        spellCheck='false'
        value={selectedTag?.tag_Name}
        onChange={(e) => {
          const tag: iAdminTag = {
            id: selectedTag!.id,
            tag_Name: e.target.value,
            forms_Using: selectedTag!.forms_Using,
            submissions_Using: selectedTag!.submissions_Using
          }
          setSelectedTag(tag);
        }}
        onFocus={e => e.target.select()}
      />
    </ConfirmationDialog>}

    {isDeleteDialogOpen && <ConfirmationDialog
      ariaPrefix='delete-tag'
      dialogTitle='Delete Tag'
      singleButton={false}
      ctaButtonText='Delete'
      ctaButtonWorkingText='Deleting...'
      cancelButtonText='Cancel'
      open={isDeleteDialogOpen}
      setOpenDialog={setIsDeleteDialogOpen}
      isWorking={(isDeleting)}
      setIsWorking={() => {
        handleDeleteTagClick();
      }}
    >
      <Typography variant='body2' sx={{ mb: 2 }}>This will permanently delete this tag and <b>remove it from {getFormAndApplicantCountMessage(selectedTag!.forms_Using, selectedTag!.submissions_Using)}</b></Typography>
    </ConfirmationDialog>}

    {isAddDialogOpen && <ConfirmationDialog
      ariaPrefix='add-tags'
      dialogTitle='Add Tags'
      singleButton={false}
      ctaButtonText='Save'
      ctaButtonWorkingText='Saving...'
      cancelButtonText='Cancel'
      open={isAddDialogOpen}
      setOpenDialog={setIsAddDialogOpen}
      isWorking={(isAdding)}
      setIsWorking={() => {
        handleAddTagClick();
      }}
    >
      <Autocomplete
        id={`add-tags-autocomplete`}
        getOptionLabel={(option: any) => option.tag_Name || ''}
        multiple={true}
        options={orgTags}
        value={selectedTags}
        disableClearable={true}
        loading={orgTags.length === 0}
        filterOptions={filterOptions}
        onChange={(e, value: any) => {
          let myArray: iTagDTO[] = value;
          myArray.forEach((item, index) => {
            if (item.id === -1) {
              myArray.splice(index, 1);
              let currentTextInput: any = textRef;
              let newTagCreated: iTagDTO = { id: 0, tag_Name: currentTextInput.current.value, org_Id: organization!.id };
              if (newTagCreated.tag_Name) {
                myArray.push(newTagCreated);
              }
            }
          });

          setSelectedTags(myArray);
        }}
        renderInput={(params) =>
            <TextField
                inputRef={textRef}
                {...params}
                label='Tags'
                />}
        //Style the selected items in the dropdown list
        slotProps={{
            popper: {
                sx: {
                    '& .MuiAutocomplete-listbox .MuiAutocomplete-option.Mui-focused': {
                        backgroundColor: inceptiaGreenAlphaColors.hover
                    },
                    '& .MuiAutocomplete-listbox .MuiAutocomplete-option[aria-selected="true"]': {
                        backgroundColor: inceptiaGreenAlphaColors.selected
                    },
                    '& .MuiAutocomplete-listbox .MuiAutocomplete-option[aria-selected="true"].Mui-focused': {
                        backgroundColor: inceptiaGreenAlphaColors.selectedHover
                    },
                    
                }
            }
        }}
        sx={{ width: '98%' }}
    />
    </ConfirmationDialog>}
  </>);
};

const TableTemplate = ({ action, children }: { action: AdminFormCrudActionType, children: React.ReactNode }): JSX.Element => {
  return (
    <TableContainer sx={{
      ...(action === 'add' && { mb: 6.25 })
    }}>
      <Table aria-label="to-do list table" sx={{justifyContent: 'left'}}>
        <TableHead>
          <TableRow>
            <TableCell component="th" sx={comTableSx.tableHead}>Name</TableCell>
            <TableCell component="th" sx={comTableSx.tableHead}>Forms Using</TableCell>
            <TableCell component="th" sx={comTableSx.tableHead}>
              {/* Fix for empty header cell accessibility error */}
              <Box sx={visuallyHidden}>Actions</Box>
            </TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {children}
        </TableBody>
      </Table>
    </TableContainer>
  )
}

export default FormCrudTags;