import { enqueueSnackbar } from 'notistack';
import KeycloakService from '../services/KeycloakService';
import { idleTimout } from '../utilities/HelperFunctions';

interface ApiOptions {
    route: string,
    method?: string | undefined,
    body?: {} | undefined,
}

const resetTimer = idleTimout(KeycloakService.doLogout, process.env.REACT_APP_IDLE_TIMEOUT);

class DashboardApi {
    async call(options: ApiOptions): Promise<Response> {
        let headers;
        resetTimer(); 
        if(KeycloakService.isLoggedIn()){   
            headers = {
                'Content-Type': 'application/json',
                Authorization: `Bearer ${ KeycloakService.getToken() }`
            
            }
        }else{
            headers = {
                'Content-Type': 'application/json',
            }
        }
        return await fetch(`${process.env.REACT_APP_DASHBOARD_API_URL}/${options.route}`, {
            method: options.method,
            headers: headers,
            body: options.body? JSON.stringify(options.body) : undefined
        })
        .catch((error) => {
            console.log(`${DashboardApi.name}: call without data error: ${error}`);
            throw error;
        });
    }

    async callForData<P>(options: ApiOptions,displayErrorMessage=true): Promise<P> {
        let headers;
        resetTimer();
        if(KeycloakService.isLoggedIn()){    
            headers = {
                'Content-Type': 'application/json',
                Authorization: `Bearer ${ KeycloakService.getToken() }`
            
            }
        }else{
            headers = {
                'Content-Type': 'application/json',
            }
        }
        let data = null;
        try {
          const response = await fetch(`${process.env.REACT_APP_DASHBOARD_API_URL}/${options.route}`, {
            method: options.method,
            headers: headers,
            body: options.body? JSON.stringify(options.body) : undefined
          });

          if ( ! response.ok) {
            if(displayErrorMessage){
                var message = await response.text();
                //todo when we fix how the sign up page is displayed, we can have a generic error, for now only show the message if we intend to
                if(!message){
                    message = "An error occurred while processing your request. Please try again later.";
                }
                enqueueSnackbar(message, { variant: "error" });
            }
            

            if (response.status === 400) { // BadRequest
              // If the API is providing an error message along with returning a BadRequest, wait for it and pass it along
              const errorData = await response.json();
              throw new Error(errorData.message);
            }

            throw new Error(`${DashboardApi.name}: call for data error: ${response.statusText}`);
          }

          // If the response status code is 204 (NO_CONTENT), don't try to parse the response as JSON, just return null
          // Attempting to fix the "unexpected end of JSON input" error
          (response.status === 204) ? data = null : data = await response.json();

        } catch (error) {
          console.log(`${DashboardApi.name} catch`, error);
          throw error;
        }
        return data;
    }
}

export default new DashboardApi();